/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  stringifyRequestQuery,
} from '../../../../_metronic/helpers'
import { useQueryRequest } from './QueryRequestProvider'
import { ChildProps } from '../../../../_metronic/layout/core'
import { favoriteNewsConst, newsConst } from '../../../helpers/Constant'
import { get, getList, postObject } from '../../../modules/auth/core/_requests'

const QueryResponseContext = createResponseContext<any>(initialQueryResponse)
const QueryResponseProvider: FC<ChildProps> = ({ children }) => {
  const { state } = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    setQuery(updatedQuery)
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    newsConst.getCapturedNews + "&isFiltered?" + state.isFiltered + "&filterObj1=" + state.filteredObj?.languages +
    "&filterObj1=" + state.filteredObj?.countries +
    "&filterObj1=" + state.filteredObj?.firstDate +
    "&filterObj1=" + state.filteredObj?.secondDate +
    "&filterObj1=" + state.filteredObj?.timeInterval,
    () => {
      if (state.isFiltered) {
        return postObject(`${newsConst.getFilteredNews}`, state.filteredObj)
      }
      else if (state.search.length > 0) {
        return postObject(newsConst.getNewsFromCache + "?keyword=" + state.search, { pageNumber: state.page, pageSize: state.items_per_page, })
      } else {
        return get(newsConst.getCapturedNews)
      }
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  )

  return (
    <QueryResponseContext.Provider value={{ isLoading: isFetching, refetch, response, query }}>
      {children}
    </QueryResponseContext.Provider>
  )
}


const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const { response } = useQueryResponse()

  if (!response) {
    return []
  }
  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const { response } = useQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
