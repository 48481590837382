import { FC, useState } from 'react'
import _ from 'lodash';
import INewsItem from '../../../../@types/newsItem';
import LanguageData from '../../../../app/services/languages.json'
import { KTSVG } from '../../../helpers';

type Props = {
  news: INewsItem,
  clearTranslate: any,
  translateNews: any,
  isLoadingTranslate: any,
  setShowTranslate: any,
  setIsLoadingTranslate: any,
  setSelectedLanguage: any,
  selectedLanguage: any
}

const TranslateCell: FC<Props> = ({ news, clearTranslate, isLoadingTranslate, setShowTranslate, setIsLoadingTranslate, translateNews, setSelectedLanguage, selectedLanguage }) => {

  return (
    <div className='text-muted fw-bold mt-1' style={{ fontSize: 11 }}>
      <select
        onChange={(event) => {
          setSelectedLanguage(event.target.value)
        }}
        value={selectedLanguage}
        name='languageCode'
        style={{ width: 145, marginRight: '10px' }}
        className='form-select form-select-solid form-select-lg fw-bold'
      >
        {LanguageData?.length > 0 &&
          LanguageData.map((l, key) => {
            return (
              <option value={l.code} key={l.code}>
                {l.value}
              </option>
            )
          })}
      </select>
      <button
        disabled={isLoadingTranslate}
        className='btn btn-dark btn-hover-scale me-5 btn-sm'
        style={{ width: 145 }}
        onClick={async () => {
          setShowTranslate(true)
          setIsLoadingTranslate(true)
          await translateNews()
          setShowTranslate(true)
          setIsLoadingTranslate(false)
        }}
      >
        {isLoadingTranslate ? 'Loading...' : 'Translate'}
      </button>
      {news.translatedLanguage && (
        <div
          onClick={() => {
            clearTranslate()
          }}
          style={{
            position: 'absolute',
            marginLeft: '12rem',
            marginTop: '-5rem',
            cursor: "pointer"
          }}
        >
          <KTSVG path={'/media/icons/return.svg'} className='svg-icon-1 text-center' />
        </div>
      )}
    </div>
  )
}

export { TranslateCell }


