export const privateRoutes = [
  'news-resource-add',
  'word-create',
  'email-add',
  'role-list',
  'role-add',
  'user-add',
  'user-list',
]
export const adminRoutes = [
  // News Module
  {
    to: 'news-module',
    title: 'News Module',
    fontIcon: 'bi-archive',
    hasSub: [
      {
        to: '/saved-news',
        title: 'Saved News',
      },
      {
        to: '/live-news',
        title: 'Live News',
      },
    ],
  },
  // News Resources Module
  {
    to: 'news-resource-request-module',
    title: 'News Resources Module',
    fontIcon: 'bi-archive',
    hasSub: [
      {
        to: '/news-resources',
        title: 'News Resources',
      },
      {
        to: '/news-resource-add',
        title: 'News Resource Create',
      },
      {
        to: '/news-resource-requests',
        title: 'News Resource Requests',
      },
      //sikinti
      {
        to: '/pending-requests',
        title: 'Pending Requests',
      },
      { 
        to: '/news-resource-make-request',
        title: 'News Resource Make Request',
      }, 
    ],
  },
  // Word Module
  {
    to: 'word-module',
    title: 'Word Module',
    fontIcon: 'bi-archive',
    hasSub: [
      {
        to: '/saved-words',
        title: 'Word List',
        fontIcon: 'bi-archive',
      },
      {
        to: '/word-create',
        title: 'Word Create',
        fontIcon: 'bi-archive',
      },
    ],
  },
  // Email Module
  {
    to: 'email-module',
    title: 'Email Module',
    fontIcon: 'bi-archive',
    hasSub: [
      {
        to: 'email-list',
        title: 'Email List',
      },
      {
        to: '/email-add',
        title: 'Create e-mail address for newsfeed',
      },
    ],
  },
  // User Module
  {
    to: 'user-module',
    title: 'User Module',
    fontIcon: 'bi-archive',
    hasSub: [
      {
        to: '/user-list',
        title: 'User List',
      },
      {
        to: '/user-add',
        title: 'Create User',
      }, 
    ],
  },
  // Role Module
  {
    to: 'role-module',
    title: 'Role Module',
    fontIcon: 'bi-archive',
    hasSub: [
      {
        to: '/role-list',
        title: 'Role List',
      },
      {
        to: '/role-add',
        title: 'Create Role',
      },
    ],
  },
]

export const userRoutes = [
  // News Module
  {
    to: 'news-module',
    title: 'News Module',
    fontIcon: 'bi-archive',
    hasSub: [
      {
        to: '/saved-news',
        title: 'Saved News',
      },
      {
        to: '/live-news',
        title: 'Live News',
      },
    ],
  },
  // News Resources Module
  {
    to: 'news-resource-request-module',
    title: 'News Resources Module',
    fontIcon: 'bi-archive',
    hasSub: [
      {
        to: '/news-resources',
        title: 'News Resources',
      },
      {
        to: '/news-resource-requests',
        title: 'News Resource Requests',
      },
      {
        to: '/pending-requests',
        title: 'Pending Requests',
      },
      {
        to: '/news-resource-make-request',
        title: 'News Resource Make Request',
      },
    ],
  },
  // Word Module
  {
    to: 'word-module',
    title: 'Word Module',
    fontIcon: 'bi-archive',
    hasSub: [
      {
        to: 'saved-words',
        title: 'Word List',
        fontIcon: 'bi-archive',
      },
    ],
  },
  // Email Module
  {
    to: 'email-module',
    title: 'Email Module',
    fontIcon: 'bi-archive',
    hasSub: [
      {
        to: 'email-list',
        title: 'Email List',
        hasAdmin: true,
        hasUser: true
      }
    ],
  },
  // User Module
  // {
  //   to: 'user-module',
  //   title: 'User Module',
  //   fontIcon: 'bi-archive',
  //   hasSub: [
  //     {
  //       to: 'user-list',
  //       title: 'User List',
  //     }
  //   ],
  // },
  // Role Module
  // {
  //   to: 'role-module',
  //   title: 'Role Module',
  //   fontIcon: 'bi-archive',
  //   hasAdmin: true,
  //   hasSub: [
  //     {
  //       to: 'role-list',
  //       title: 'Role List',
  //     }
  //   ],
  // },
]