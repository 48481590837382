import { Column } from 'react-table'
import { CapturedWordsCell } from '../../../../../../_metronic/layout/components/columns/CapturedWordsCell'
import { DateCell } from '../../../../../../_metronic/layout/components/columns/DateCell'
import { NewsSiteCell } from '../../../../../../_metronic/layout/components/columns/NewsSiteCell'
import NewsItem from '../../../../../../@types/newsItem'
import { CustomHeader } from './CustomHeader'


const dashboardNewsTableColumn: ReadonlyArray<Column<NewsItem>> = [
    {
        Header: (props) => <CustomHeader tableProps={props} title='Title' className='min-w-125px' />,
        id: 'title',
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Description' className='min-w-125px' />,
        id: 'description',
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Publish Date' className='min-w-125px' />,
        id: 'publishDate',
        Cell: ({ ...props }) => <DateCell date={props.data[props.row.index].publishDate} />,
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='News Site' className='min-w-125px' />,
        id: 'newsSite',
        Cell: ({ ...props }) => <NewsSiteCell newsSite={props.data[props.row.index].newsSite} url={props.data[props.row.index].url} />,
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Captured Words' className='min-w-150px' />,
        id: 'capturedWords',
        Cell: ({ ...props }) => <CapturedWordsCell capturedWords={props.data[props.row.index].capturedWords} />,
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Translate' className='min-w-125px text-center' />,
        id: 'languageCode',
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Favorite' className='min-w-125px' />,
        id: 'favNews',
    },
]

export { dashboardNewsTableColumn }
