import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup } from '@mui/material'
import { KTSVG } from '../../../_metronic/helpers'
import { toast } from 'react-toastify'
import countriesData from '../../services/countries.json'
import languagesData from '../../services/languages.json'
import { MenuComponent } from '../../../_metronic/assets/ts/components'
import { useAuth } from '../auth'
import SelectDropdown from './SelectDropdown'
import INewsItem from '../../../@types/newsItem'
import IFilterNews from '../../../@types/filterNews'
import { favoriteNewsConst, newsConst } from '../../helpers/Constant'
import { get, postObject } from '../auth/core/_requests'

interface FilterNewsProps {
  setFilteredObj: (obj: IFilterNews) => void
  clearFilter: () => void
  isFiltered?: boolean
}

export function FilterNews({
  setFilteredObj,
  clearFilter,
  isFiltered
}: FilterNewsProps) {
  const [newsFilteredObject, setNewsFilteredObject] = useState({
    firstDate: null as string | null,
    secondDate: null as string | null,
    countries: null as string[] | null,
    languages: null as string[] | null,
    timeInterval: null as string | null,
  })

  const [countries, setCountries] = useState<string[]>([])
  const [languages, setLanguages] = useState<string[]>([])
  const [timeInterval, setTimeInterval] = useState('')

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const onFirstDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewsFilteredObject((prevState) => ({
      ...prevState,
      firstDate: e.target.value,
    }))
  }

  const onSecondDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewsFilteredObject((prevState) => ({
      ...prevState,
      secondDate: e.target.value,
    }))
  }

  const clearFilterOptions = () => {
    setNewsFilteredObject({
      firstDate: null,
      secondDate: null,
      countries: null,
      languages: null,
      timeInterval: null,
    })
    setLanguages([])
    setCountries([])
    clearFilter();
    setTimeInterval("")
  }

  const filterNews = async () => {
    let filteredObject: IFilterNews = {
      firstDate: newsFilteredObject.firstDate,
      secondDate: newsFilteredObject.secondDate,
      countries: countries,
      languages: languages,
      timeInterval: timeInterval,
    }
    setFilteredObj(filteredObject)
  }

  const clearFilterNews = () => {
    setLanguages([])
    setCountries([])
    clearFilter()
  }

  const handleCountrySelectChange = (selected: string[]) => {
    setCountries(selected)
  }

  const handleLanguageSelectChange = (selected: string[]) => {
    setLanguages(selected)
  }

  return (
    <>
      <button
        type='button'
        className='btn btn-light-primary mt-1'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/gen031.svg' className='svg-icon-2' />
        Filter
      </button>
      {isFiltered && (
        <button
          type='button'
          className='btn btn-light-primary'
          style={{ marginLeft: 5 }}
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          onClick={() => clearFilterNews()}
        >
          Clear Filter
        </button>
      )}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>

        <div className='separator border-gray-200'></div>

        <div className='px-7 py-5'>
          <div className='row'>
            <div className='row'>
              <div className='col'>
                <label className='second-date-label'>Start Date: </label>
              </div>
              <div className='col'>
                <input
                  type='date'
                  className='form-control form-control-lg form-control-solid'
                  value={newsFilteredObject.firstDate || ''}
                  onChange={onFirstDateChange}
                />
              </div>
            </div>

            <div className='row mt-4'>
              <div className='col'>
                {' '}
                <label className='second-date-label'>End Date: </label>
              </div>
              <div className='col'>
                <input
                  type='date'
                  className='form-control form-control-lg form-control-solid'
                  value={newsFilteredObject.secondDate || ''}
                  onChange={onSecondDateChange}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col mt-5'>
                <SelectDropdown
                  options={countriesData}
                  selectedOptions={countries}
                  onChange={handleCountrySelectChange}
                  placeHolder='Select Country'
                  multiple={true}
                />
              </div>
            </div>

            <div className='row'>
              <div className='ol mt-5'>
                <SelectDropdown
                  options={languagesData}
                  selectedOptions={languages}
                  onChange={handleLanguageSelectChange}
                  placeHolder='Select Language'
                  multiple={true}
                />
              </div>
            </div>
          </div>
          <div className='time-interval mt-5'>
            <ButtonGroup size='small' aria-label='small button group'>
              <Button
                onClick={() => setTimeInterval('allNews')}
                style={{
                  backgroundColor: timeInterval === 'allNews' ? '#1e1e2d' : '#fff',
                  color: timeInterval === 'allNews' ? '#fff' : '#000',
                  fontWeight: timeInterval === 'allNews' ? 'bold' : 'normal',
                }}
              >
                All News
              </Button>

              <Button
                onClick={() => setTimeInterval('today')}
                style={{
                  backgroundColor: timeInterval === 'today' ? '#1e1e2d' : '#fff',
                  color: timeInterval === 'today' ? '#fff' : '#000',
                  fontWeight: timeInterval === 'today' ? 'bold' : 'normal',
                }}
              >
                Today
              </Button>

              <Button
                onClick={() => setTimeInterval('thisWeek')}
                style={{
                  backgroundColor: timeInterval === 'thisWeek' ? '#1e1e2d' : '#fff',
                  color: timeInterval === 'thisWeek' ? '#fff' : '#000',
                  fontWeight: timeInterval === 'thisWeek' ? 'bold' : 'normal',
                }}
              >
                This Week
              </Button>

              <Button
                onClick={() => setTimeInterval('lastThirtyDays')}
                style={{
                  backgroundColor: timeInterval === 'lastThirtyDays' ? '#1e1e2d' : '#fff',
                  color: timeInterval === 'lastThirtyDays' ? '#fff' : '#000',
                  fontWeight: timeInterval === 'lastThirtyDays' ? 'bold' : 'normal',
                }}
              >
                Last 30 days
              </Button>
            </ButtonGroup>
          </div>
          <div className='d-flex justify-content-end mt-5'>
            <button
              type='button'
              className='btn btn-primary fw-bold px-6'
              onClick={() => filterNews()}
            >
              Apply
            </button>

            <button
              type='button'
              className='btn btn-danger fw-bold px-6'
              style={{ marginLeft: '5px' }}
              onClick={() => clearFilterOptions()}
            >
              Clear
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
