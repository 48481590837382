/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, Dispatch, SetStateAction } from 'react'


import { FC } from 'react'
import { KTSVG, useDebounce } from '../../helpers'

type Props = {
    setSearch: any
    placeholder?: string
    setCurrentPage?: any
    refresh?: boolean
    setRefresh?: Dispatch<SetStateAction<boolean>>
}

const HeaderSearchComponent: FC<Props> = ({ setSearch, placeholder, setCurrentPage, refresh, setRefresh }) => {
    const urlSearchTerm = window.location.href.split("searchTerm=")[1]
    const [searchTerm, setSearchTerm] = useState<string>(urlSearchTerm || '')
    // Debounce search term so that it only gives us latest value ...
    // ... if searchTerm has not been updated within last 500ms.
    // The goal is to only have the API call fire when user stops typing ...
    // ... so that we aren't hitting our API rapidly.
    const debouncedSearchTerm = useDebounce(searchTerm, 150)
    // Effect for API call
    useEffect(
        () => {
            if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
                setSearch(debouncedSearchTerm)
                setCurrentPage && setCurrentPage(1)
            }
        },
        [debouncedSearchTerm] // Only call effect if debounced search term changes
        // More details about useDebounce: https://usehooks.com/useDebounce/
    )

    useEffect(() => {
        if (refresh) {
            setSearchTerm('')
            setRefresh && setRefresh(false)
        }
    }, [refresh])



    return (
        <div className='card-title'>
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>
                <KTSVG
                    path='/media/icons/gen021.svg'
                    className='svg-icon-1 position-absolute ms-6'
                />
                <input
                    type='text'
                    data-kt-user-table-filter='search'
                    className='form-control form-control-solid w-200px ps-14'
                    placeholder={placeholder}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{ borderColor: 'gray' }}
                />
            </div>
            {/* end::Search */}
        </div>
    )
}

export { HeaderSearchComponent }
