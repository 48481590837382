import { Route, Routes, Outlet } from 'react-router-dom'
import { ProccessingModal } from '../../../_metronic/layout/components/modal/ProccessingModal'
import { ModalViewProvider } from '../../providers/ModalProvider'
import { CustomListWrapper } from './screens/DashboardNewsList'

const DashboardNewsPage = () => {
  return (
    <ModalViewProvider>
      <>
            <Routes>
              <Route element={<Outlet />}>
                <Route
                  path='/*'
                  element={
                    <>
                      <CustomListWrapper />
                    </>
                  }
                />
                </Route>
            </Routes>
            <ProccessingModal navigationKey={"/news"} />
          </>
    </ModalViewProvider>
  )
}

export default DashboardNewsPage
