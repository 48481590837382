import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import 'yup-phone';
import { toast } from 'react-toastify';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import AvatarChange from './AvatarChange';
import { useAuth } from '../../auth';
import { postObject } from '../../auth/core/_requests';
import { userInfoConst } from '../../../helpers/Constant';

export default function ProfileInfoUpdateForm() {
  
  const [loading, setLoading] = useState(false);
  const [showIsAvatars, setShowIsAvatars] = useState(false);
  const { currentUser, setCurrentUser } = useAuth();


  const initialValues = {
    id: currentUser?.id,
    userId: currentUser?.userId,
    firstName: currentUser?.firstName,
    lastName: currentUser?.lastName,
    email: currentUser?.email,
    company: currentUser?.company,
    phoneNumber: currentUser?.phoneNumber,
    companySite: currentUser?.companySite,
    avatarURL: currentUser?.avatarURL,
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values:any) => {
      setLoading(true);
      setTimeout(() => {
        postObject(userInfoConst.update, values)
        .then((result) => {
          toast.success(result.data.message);
          setCurrentUser(values);
        });
        setLoading(false);
      }, 1000);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} noValidate className='form'>
      <div className='card-body border-top p-9'>
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
          <div className='col-lg-2'>
            <div className='image-input'>
              <img style={{ width: '160px', height: '160px' }} src={toAbsoluteUrl(`${currentUser?.avatarURL}`)} />
              <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                style={{ float: 'right', position: 'absolute', right: 0 }}
              >
                <i className='bi bi-pencil-fill fs-7' onClick={() => setShowIsAvatars(!showIsAvatars)} />
              </span>
            </div>
          </div>
          <div className='col-lg-6'>{showIsAvatars && <AvatarChange setShowIsAvatars={setShowIsAvatars} />}</div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>Full Name</label>
          <div className='col-lg-8'>
            <div className='row'>
              <div className='col-lg-6 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='First name'
                  {...formik.getFieldProps('firstName')}
                />
                {formik.touched.firstName && formik.errors.firstName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.firstName}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-6 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Last name'
                  {...formik.getFieldProps('lastName')}
                />
                {formik.touched.lastName && formik.errors.lastName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.lastName}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>Email</label>
          <div className='col-lg-8 fv-row'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder='Email'
              {...formik.getFieldProps('email')}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.email}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>Company</label>
          <div className='col-lg-8 fv-row'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder='Company name'
              {...formik.getFieldProps('company')}
            />
            {formik.touched.company && formik.errors.company && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.company}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span>Phone Number</span>
          </label>

          <div className='col-lg-8 fv-row'>
            <input
              type='tel'
              className='form-control form-control-lg form-control-solid'
              placeholder='Phone number'
              {...formik.getFieldProps('phoneNumber')}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.phoneNumber}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span>Company Site</span>
          </label>

          <div className='col-lg-8 fv-row'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder='Company Site'
              {...formik.getFieldProps('companySite')}
            />
            {formik.touched.companySite && formik.errors.companySite && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.companySite}</div>
              </div> 
            )}
          </div>
        </div>
      </div>

      <div className='card-footer d-flex justify-content-end py-6 px-9'>
        <button type='submit' className='btn btn-primary' disabled={loading}>
          {!loading ? 'Save Changes' : (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  );
}
