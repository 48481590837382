import React, { useEffect, useState } from 'react';

interface PasswordStrengthBarProps {
  password: string;
}

export default function PasswordStrengthBar({password}:PasswordStrengthBarProps){
  const [barColor, setBarColor] = useState<string>('#ddd');
  const [barWidth, setBarWidth] = useState<number>(0);
  const [barText, setBarText] = useState<string>('');

  const calculatePasswordStrength = () => {
    let strengthLevel = 0;

    if (password.length >= 8) {
      strengthLevel++;
    }

    if (/[a-z]/.test(password)) {
      strengthLevel++;
    }

    if (/[A-Z]/.test(password)) {
      strengthLevel++;
    }

    if (/\d/.test(password)) {
      strengthLevel++;
    }

    if (/[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/.test(password)) {
      strengthLevel++;
    }

    return strengthLevel;
  };

  const updateBar = () => {
    const strengthLevel = calculatePasswordStrength();

    if (strengthLevel === 0) {
      setBarColor('#ddd');
      setBarWidth(0);
      setBarText('');
    } else if (strengthLevel === 1) {
      setBarColor('red');
      setBarWidth(20);
      setBarText('Weak');
    } else if (strengthLevel === 2) {
      setBarColor('orange');
      setBarWidth(40);
      setBarText('Fair');
    } else if (strengthLevel === 3) {
      setBarColor('yellow');
      setBarWidth(60);
      setBarText('Good');
    } else if (strengthLevel === 4) {
      setBarColor('green');
      setBarWidth(100);
      setBarText('Strong');
    } 
    // else if (strengthLevel === 5) {
    //   setBarColor('green');
    //   setBarWidth(100);
    //   setBarText('Very Strong');
    // }
  };

  useEffect(() => {
    updateBar();
  }, [password]);

  return (
    <div>
      <div style={{ border: '1px solid #ddd', width: '1190px', height: '20px' }}>
        <div
          style={{
            width: `${barWidth}%`,
            height: '100%',
            backgroundColor: barColor,
            transition: 'width 0.5s',
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: '-25px',
            right: '0',
            fontSize: '12px',
          }}
        >
          {barText}
        </div>
      </div>
    </div>
  );
}