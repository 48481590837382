import React from 'react'
import PasswordChange from './PasswordChange'
import ProfileInfoUpdateForm from './ProfileInfoUpdateForm'
import UserRoles from './UserRoles'

const ProfileDetails = () => {


  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Profile Details</h3>
        </div>
      </div>
      

      <div id='kt_account_profile_details' className='collapse show'>
      {/* Forms */}
        <ProfileInfoUpdateForm />
        <UserRoles />
        <PasswordChange />
      {/* Forms */}
      
      </div>
    </div>
  )
}

export {ProfileDetails}
