import clsx from 'clsx'
import { FC, useState } from 'react'
import { Row } from 'react-table'
import INewsItem from '../../../../../@types/newsItem'
import NewsItem from '../../../../../@types/newsItem'
import { DescriptionCell } from '../../../../../_metronic/layout/components/columns/DescriptionCell'
import { TitleCell } from '../../../../../_metronic/layout/components/columns/TitleCell'
import { TranslateCell } from '../../../../../_metronic/layout/components/columns/TranslateCell'
import AddToFavoriteNewsButton from '../../../../components/AddToFavoriteNewsButton'
import { newsConst } from '../../../../helpers/Constant'
import { postObject } from '../../../../modules/auth/core/_requests'

type Props = {
  row: Row<NewsItem>
  setNewsArray: (data: INewsItem) => void
  isFavorite: boolean
}

const CustomRow: FC<Props> = ({ row, setNewsArray, isFavorite }) => {

  const [isLoadingTranslate, setIsLoadingTranslate] = useState(false)
  const [showTranslate, setShowTranslate] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState(
    row.original.translatedLanguage ||
    row.original.languageCode || row.original.newsSource?.languageCode
  )

  const translateNews = async () => {
    if (selectedLanguage !== '') {
      setIsLoadingTranslate(true)
      try {
        const API_URL = `${newsConst.translatedNews}?fromLanguage=${row.original.languageCode}&toLanguage=${selectedLanguage}`
        await postObject(API_URL, row.original).then((res: INewsItem) => {
          setNewsArray({
            ...row.original,
            titleTranslated: res.data.title,
            descriptionTranslated: res.data.description,
            capturedWords: res.data.capturedWords,
            translatedLanguage: selectedLanguage,
          })
        })

      } catch (error) {
        console.error(error)
      }

      setIsLoadingTranslate(false)
    }
  }

  const clearTranslate = () => {
    setNewsArray({
      ...row.original,
      titleTranslated: null,
      descriptionTranslated: null,
      capturedWords: row.original.capturedWords,
      translatedLanguage: null,
    });
    setShowTranslate(false);
    setSelectedLanguage(
      row.original.translatedLanguage ||
      row.original.languageCode || row.original.newsSource?.languageCode
    );
    setIsLoadingTranslate(false);
  };

  return (
    <tr {...row.getRowProps()}>
      {row.cells.map((cell) => {
        if (cell.column.id === 'title') {
          return (
            <td
            {...cell.getCellProps()}
            className={clsx({ 'text-end min-w-100px': false })}
          >
            <TitleCell row={row.original} title={row.original?.title} translatedTitle={row.original?.titleTranslated} capturedWords={row.original?.capturedWords} />
            </td>
          )
        }

        if (cell.column.id === "description") {
          return (
            <td
              {...cell.getCellProps()}
              className={clsx({ 'text-end min-w-100px': false })}
            >
              <DescriptionCell description={row.original.description} descriptionTranslated={row.original?.descriptionTranslated}  capturedWords={row.original?.capturedWords} newsLink={row.original.linkOfNews} />
            </td>
          )
        }

        if (cell.column.id === 'languageCode') {
          return (
            <td
              {...cell.getCellProps()}
              className={clsx({ 'text-end min-w-100px': false })}
            >
              <a className='text-dark fw-bolder text-hover-primary fs-6'>
                <TranslateCell isLoadingTranslate={isLoadingTranslate}
                  setShowTranslate={setShowTranslate}
                  setIsLoadingTranslate={setIsLoadingTranslate}
                  setSelectedLanguage={setSelectedLanguage}
                  selectedLanguage={selectedLanguage}
                  news={cell.row.original} translateNews={translateNews} clearTranslate={clearTranslate} />
              </a>
            </td>
          )
        }

        if (cell.column.id === "favNews") {
          return (
            <td
              {...cell.getCellProps()}
              className={clsx({ 'text-end min-w-100px': false })}
            >
              <AddToFavoriteNewsButton news={row.original} isFavorite={isFavorite} />
            </td>
          )
        }

        return (
          <td
            {...cell.getCellProps()}
            className={clsx({ 'text-end min-w-100px': cell.column.id === 'actions' })}
          >
            <a className='text-dark fw-bolder text-hover-primary fs-6'>
              {cell.render('Cell')}
            </a>
          </td>
        )
      })}
    </tr>
  )
}

export { CustomRow }
