import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import AddToFavoriteNews from '../../@types/addToFavoriteNews';
import INewsItem from '../../@types/newsItem';
import { KTSVG } from '../../_metronic/helpers';
import { favoriteNewsConst } from '../helpers/Constant';
import { useAuth } from '../modules/auth';
import { postObject } from '../modules/auth/core/_requests';
import { useMutation } from 'react-query';

interface AddToFavoriteNewsButtonProps {
  news: INewsItem;
  isFavorite: boolean;
}

export default function AddToFavoriteNewsButton({ news, isFavorite }: AddToFavoriteNewsButtonProps) {
  const { currentUser } = useAuth();
  const [favorite, setFavorite] = useState(isFavorite);

  useEffect(() => {
    setFavorite(isFavorite);
  }, [isFavorite]);

  const { mutate: addToFavoriteNewsMutation } = useMutation(
    (favNewsItem: AddToFavoriteNews) => {
      const API_URL = `${favoriteNewsConst.addToFavoriteNews}?userId=${currentUser?.userId}`;
      return postObject(API_URL, favNewsItem);
    },
    {
      onSuccess: () => {
        setFavorite(true);
      },
      onError: () => {
        toast.error("Failed to add to favorites");
      },
    }
  );

  const { mutate: removeFavoriteNewsMutation } = useMutation(
    () => {
      const API_URL = `${favoriteNewsConst.removeToFavoriteNews}?userId=${currentUser?.userId}`;
      return postObject(API_URL, news);
    },
    {
      onSuccess: () => {
        setFavorite(false);
      },
      onError: () => {
        toast.error("Failed to remove from favorites");
      },
    }
  );

  const handleClick = () => {
    if (favorite) {
      removeFavoriteNewsMutation();
    } else {
      addToFavoriteNewsMutation({
        title: news.title,
        description: news.description,
        publishDate: news.publishDate,
        newsSourceId: news.newsSourceId || news.newsSource.id,
        linkOfNews: news.linkOfNews,
        capturedWords: news.capturedWords || "",
      });
    }
  };

  return (
    <div
      onClick={handleClick}
      style={{ cursor: 'pointer', position: 'relative', marginLeft: '1rem' }}
    >
      <div style={{ position: 'absolute', left: 0 }}>
        <KTSVG path={favorite ? '/media/icons/remove-favorite.svg' : '/media/icons/add-favorite.svg'} className='svg-icon-1 text-center' />
      </div>
    </div>
  );
}
