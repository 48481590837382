/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { useState } from 'react'
import IFilterNews from '../../../../@types/filterNews'
import { HeaderSearchComponent } from '../../../../_metronic/layout/components/Search'
import { FilterNews } from '../../../modules/filterNews/FilterNews'
import { useQueryRequest } from '../core/QueryRequestProvider'
import { useQueryResponseLoading, useQueryResponsePagination } from '../core/QueryResponseProvider'
const SearchComponent = (props: any) => {

    const pagination = useQueryResponsePagination()
    const isLoading = useQueryResponseLoading()
    const { updateState } = useQueryRequest()
    const [search, setSearch] = useState(pagination.search)
    const [refresh, setRefresh] = useState(false)


    const updatePage = () => {
        if (isLoading) {
            return
        }

        props.setSearchTerm(search)
        props.setCurrentPage(1)
        updateState({ search, page: 1 })
    }

    const updatePageRefresh = () => {
        setSearch('')
        props.setSearchTerm("")
        setRefresh(true)
        updateState({ search: '', page: 1 })
    }

    const setFilteredObj = (obj: IFilterNews) => {
        if (isLoading) {
            return
        }
        updateState({ isFiltered: true, page: 1, search: '', filteredObj: obj })
    }


    const clearFilter = () => {
        if (isLoading) {
            return
        }
        updateState({ isFiltered: false, page: 1, search: '', })
    }

    return (
        <div style={{ display: 'flex', marginLeft: 10, marginTop: 10 }}>
            {<HeaderSearchComponent refresh={refresh} setRefresh={setRefresh} setSearch={(text: string) => { setSearch(text) }} placeholder={"Search"} />}
            <div style={{ marginLeft: 10 }} />
            {<button onClick={() => updatePage()} type='button' className='btn btn-primary' style={{ marginLeft: 10, height: 40, marginTop: 4 }}>
                Search
            </button>}
            <button onClick={() => updatePageRefresh()} type='button' className='btn btn-primary' style={{ marginLeft: 10, height: 40, marginTop: 4 }}>
                Refresh List
            </button>
            {<div style={{ marginLeft: 10, }}>
                <FilterNews clearFilter={clearFilter} isFiltered={props.isFiltered} setFilteredObj={setFilteredObj} />
            </div>}
        </div>
    )
}

export { SearchComponent }
