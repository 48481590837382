import React, { FC, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Lottie from "lottie-react";
import Loading from "../../../assets/lotties/loading.json"
import Success from "../../../assets/lotties/success.json";
import { useModalView } from '../../../../app/providers/ModalProvider';
import { postObject } from '../../../../app/modules/auth/core/_requests';

type Props = {
    navigationKey: string
}
 
const ProccessingModal: FC<Props> = ({ navigationKey }) => {

    const { title, description, show, loading, isDelete, actionUrl, modalObj, setDeleteTrigger, setUserId, setIsUpdate, setIsNew, setIsDelete, setFailed, setShow, setTitle, setDescription, setLoading } = useModalView()


    const [deleteStyle, setDeleteStyle] = useState<boolean>(isDelete)
    useEffect(() => {
        setDeleteStyle(isDelete)
    }, [isDelete])

    const handleCloseModal = () => {
        setDeleteTrigger(true)
        setShow(false)
        setFailed(false)
        setIsDelete(false)
        setIsUpdate(false)
        setIsNew(false)
        setUserId(null)
    }

    const deleteObj = async () => {
        setDeleteStyle(false)
        await postObject(actionUrl, modalObj)
        setTitle("Deleted successfully...")
        setDescription("")
        setLoading(false)
    }


    return (
        <Modal
            aria-hidden='true'
            tabIndex={-1}
            dialogClassName='modal-dialog-centered'
            show={show}
            onHide={handleCloseModal}>
            <div className="modal-header" role="document" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                {title}
            </div>
            <div className="modal-body" role="document" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                {loading ?
                    <Lottie animationData={Loading} style={{ width: 300, height: 200 }} />
                    :
                    <Lottie loop={false} animationData={Success} style={{ width: 300, height: 200 }}
                    onComplete={() => {
                        handleCloseModal()
                        window.location.reload()
                    }}
                    />
                }
                <span style={{ marginTop: 30 }}>
                    {description}
                </span>
            </div>
            {deleteStyle && <div className="modal-footer">
                        {
                            <button type="button" className="btn btn-light-danger font-weight-bold" onClick={deleteObj}>Delete</button>
                        }
                        {
                            <button type="button" className="btn btn-light-primary font-weight-bold" onClick={handleCloseModal}>Close Modal</button>
                        }
                    </div>}
        </Modal>
    )
}

export { ProccessingModal }
