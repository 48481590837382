import React, { useState, useEffect } from 'react';
import Role from '../../../../@types/role';
import { userRoleConst } from '../../../helpers/Constant';
import { useAuth } from '../../auth';
import { get } from '../../auth/core/_requests';

export default function UserRoles() {
  
  const [userRoles, setUserRoles] = useState<Role[]>([]);

  const { currentUser } = useAuth();

  const getByUserIdRolesRequestURL = `${userRoleConst.getByUserIdRoles}?userId=${currentUser?.userId}` 

    useEffect(() => {
      getByUserIdRoles()
  }, []);

  const getByUserIdRoles = () => {
    currentUser && get(getByUserIdRolesRequestURL).then((res)=> setUserRoles(res.data))
  }
  
  return (
    <div>
      <div className='card-header border-0'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Roles</h3>
        </div>
      </div>
      <div className='card-body py-4'>
        <div className='table-responsive'>
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            <thead>
              <tr className='text-start fw-bolder fs-7 text-uppercase gs-0'>
                <th role='columnheader' className='min-w-125px'>
                  Role Name
                </th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' role='rowgroup'>
              {userRoles.map((role, key) =>(
                <tr role='row' key={key}>
                <td className='text-dark fs-6'>{role.name}</td>
              </tr>
              )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
