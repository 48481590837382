export const authConst = {
  login: 'Auth/login',
  registrationForAdminOnly: 'Auth/registrationForAdminOnly',
  verifyToken: 'Auth/verifyToken',
  generateVerificationCode: 'Auth/generateVerificationCode',
  changePassword: 'Auth/changepassword',
  adminChangePassword: 'Auth/adminChangePassword',
  resetPassword: 'Auth/resetPassword',
}

export const avatarConst = {
  getAllAvatars: 'Avatars/getAllAvatars',
  changeAvatar: 'Avatars/changeAvatar',
}

export const favoriteNewsConst = {
  getByUserId: 'FavoriteNews/getByUserId',
  addToFavoriteNews: 'FavoriteNews/addToFavoriteNews',
  removeToFavoriteNews: 'FavoriteNews/removeToFavoriteNews',
  existsFavoriteNews: 'FavoriteNews/existsFavoriteNews',
  getFavoriteNewsTitlesByUserId: 'FavoriteNews/getFavoriteNewsTitlesByUserId',
  searchKeyword: 'FavoriteNews/searchKeyword',
}

export const mailRecipientConst = {
  getAll: 'MailRecipients/getAll',
  add: 'MailRecipients/add',
  update: 'MailRecipients/update',
  delete: 'MailRecipients/delete',
  searchKeyword: 'MailRecipients/searchKeyword',
}

export const newsResourceConst = {
  getAll: 'NewsSources/getAllNewsSourceDetail',
  add: 'NewsSources/add',
  update: 'NewsSources/update',
  delete: 'NewsSources/delete',
  searchKeyword: 'NewsSources/searchKeyword',
}

export const newsConst = {
  getNewsFromCache: 'News/getNewsFromCache',
  getLiveNews: 'News/getLiveNews',
  getNewsFromAllSources: 'News/getNewsFromAllSources',
  getAllSavedNews: 'News/getAllSavedNews',
  getCapturedNews: 'News/capturedNews',
  getFilteredNews: 'News/getFilteredNews',
  getNewsByTimeInterval: 'News/getNewsByTimeInterval',
  searchKeywordInSavedNews: 'News/searchKeywordInSavedNews',
  translatedNews:"News/translatedNews"
}

export const newsResourceRequestsConst = {
  getAll: 'NewsSourceRequests/getAll',
  getPendingRequests: 'NewsSourceRequests/getPendingRequests',
  updateRequestStatus: 'NewsSourceRequests/updateRequestStatus',
  add: 'NewsSourceRequests/addDto',
  update: 'NewsSourceRequests/update',
  delete: 'NewsSourceRequests/delete',
  searchKeyword: 'NewsSourceRequests/searchKeyword',
}

export const roleConst = {
  getAll: 'Roles/getAll',
  add: 'Roles/add',
  update: 'Roles/update',
  delete: 'Roles/delete',
  searchKeyword: 'Roles/searchKeyword',
}

export const savedWordConst = {
  getAll: 'SavedWords/getAll',
  add: 'SavedWords/add',
  update: 'SavedWords/update',
  delete: 'SavedWords/delete',
  searchKeyword: 'SavedWords/searchKeyword',
}

export const userConst = {
  getByEmail: 'Users/getByEmail',
}

export const userRoleConst = {
  addRolesToUser: 'UserRoles/addRolesToUser',
  removeRoleFromUser: 'UserRoles/removeRoleFromUser',
  getByUserIdRoles: 'UserRoles/getByUserIdRoles',
}

export const userInfoConst = {
  getAll: 'UserInfos/getAllUserInfo',
  getUserInfo: 'UserInfos/getUserInfo',
  update: 'UserInfos/updateUserInfo',
  searchKeyword: 'UserInfos/searchKeyword',
}
