/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */
 
import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import ForbiddenPage from '../components/ForbiddenPage'
import {privateRoutes} from './routes'
import Role from '../../@types/role'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser, userRoles} = useAuth()
  const isAdmin = userRoles.some((role: Role) => role.name === 'Admin')

  const path = window.location.pathname.split('/')

  const checkPermission = () => {
    for (var i = 0; i < privateRoutes.length; i++) {
      if (path.includes(privateRoutes[i])) {
        return true;
      }
    }
    return false;
  }
  
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            <>
              {!isAdmin && checkPermission() ? (
                <Route path='/*' element={<ForbiddenPage />} />
              ): <Route path='/*' element={<PrivateRoutes />} />}

              
              <Route index element={<Navigate to='/news' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
        {/* <Route path='forbidden-page/*' element={<ForbiddenPage />} /> */}
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
