import {FC} from 'react'
import _ from 'lodash'

type Props = {
  newsSite: string | any
  url: string | any
}

const NewsSiteCell: FC<Props> = ({newsSite, url}) => (
  <div className='text-muted fw-bold mt-1' style={{fontSize: 11}}>
    <a href={url} target='_blank' style={{color: 'black'}}>
      {newsSite}
    </a>
  </div>
)

export {NewsSiteCell}
