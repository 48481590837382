import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import countriesData from '../../../services/countries.json';
import languagesData from '../../../services/languages.json';
import { useAuth } from '../../auth';
import SelectDropdown from '../../filterNews/SelectDropdown';
import INewsResourceMakeRequest from '../../../../@types/newsResourceMakeRequest';
import { postObject } from '../../auth/core/_requests';
import { newsResourceRequestsConst } from '../../../helpers/Constant';



export function NewsResourceMakeRequest() {
  
  const [loading, setLoading] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [selectedLanguage, setSelectedLanguage] = useState<string>('');
  const [selectedLanguageCode, setSelectedLanguageCode] = useState<string>('');

  const newsSourceRequestSchema = Yup.object().shape({
    newsSite: Yup.string().required(),
    url: Yup.string().required(),
  });

  const { currentUser } = useAuth();

  const initialValues = {
    userId: currentUser?.userId,
    newsSite: '',
    url: '',
    country: selectedCountry,
    language: selectedLanguage,
    approved: false,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: newsSourceRequestSchema,
    onSubmit: async (values, {resetForm, setSubmitting}) => {
      setTimeout(() => {
        const newsSourceRequestObj:INewsResourceMakeRequest = {
          userId: currentUser?.userId,
          newsSite: values.newsSite,
          url: values.url,
          country: selectedCountry,
          language: selectedLanguage,
          languageCode: selectedLanguageCode,
          approved: values.approved,
        };
        setLoading(true)
        postObject(newsResourceRequestsConst.add, newsSourceRequestObj)
          .then((res) => {
            toast.info(res.data.message)
            resetForm()
            setSelectedCountry('')
            setSelectedLanguage('')
            setSelectedLanguageCode('')
            setLoading(false)
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
          })
      }, 100)
    },

  });

  const handleCountrySelect = (event: any) => {
    setSelectedCountry(event);
  };

  const handleLanguageSelect = (event: any) => {
    setSelectedLanguage(event);

    const selectedLanguageCode = languagesData.find(
      (language) => language.value === event
    );

    setSelectedLanguageCode(selectedLanguageCode?.code || '');
  };

  return (
    <>
      <form className='form w-100' onSubmit={formik.handleSubmit}>
        {formik.status ? (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        ) : null}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>News Site</label>
          <input
            placeholder='News Site'
            {...formik.getFieldProps('newsSite')}
            className={clsx(
              'form-control form-control-lg',
              { 'is-invalid': formik.touched.newsSite && formik.errors.newsSite },
              { 'is-valid': formik.touched.newsSite && !formik.errors.newsSite }
            )}
            type='text'
            name='newsSite'
            autoComplete='off'
          />
          {formik.touched.newsSite && formik.errors.newsSite && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.newsSite}</span>
            </div>
          )}
        </div>

        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>URL</label>
          <input
            placeholder='Url'
            {...formik.getFieldProps('url')}
            className={clsx(
              'form-control form-control-lg',
              { 'is-invalid': formik.touched.url && formik.errors.url },
              { 'is-valid': formik.touched.url && !formik.errors.url }
            )}
            type='text'
            name='url'
            autoComplete='off'
          />
          {formik.touched.url && formik.errors.url && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.url}</span>
            </div>
          )}
        </div>

        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Country</label>
          <SelectDropdown
            multiple={false}
            placeHolder='Select Country'
            onChange={handleCountrySelect}
            options={countriesData}
            selectedOptions={selectedCountry}
            className='form-control form-control-lg'
          />
          {formik.touched.country && formik.errors.country && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.country}</span>
            </div>
          )}
        </div>

        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Language</label>
          <SelectDropdown
            multiple={false}
            placeHolder='Select Language'
            onChange={handleLanguageSelect}
            options={languagesData}
            selectedOptions={selectedLanguage}
          />
          {formik.touched.language && formik.errors.language && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.language}</span>
            </div>
          )}
        </div>

        <div className='text-center'>
          <button type='submit' className='btn btn-lg btn-primary w-100 mb-5' disabled={loading}>
            {!loading ? (
              'News Resource Make Request'
            ) : (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </>
  );
}