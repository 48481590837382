import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

export default function ForbiddenPage() {

  const navigate = useNavigate()
  const returnHome = () => {
    navigate("/dashboard")
    window.location.reload()
  }

  return (
    <div className="d-flex flex-column flex-root" id="kt_app_root">
        <div className="d-flex flex-column flex-center flex-column-fluid">
          <div className="d-flex flex-column flex-center text-center p-10">
            <div className="card card-flush w-lg-650px py-5">
              <div className="card-body py-15 py-lg-20">
                <h1 className="fw-bolder fs-2hx text-gray-900 mb-4">Oops!</h1>
                <div className="fw-semibold fs-6 text-gray-500 mb-7">You are not authorized to enter this page</div>
                <div className="mb-3">
                  <img src="/media/icons/forbidden.png" className="mw-100 mh-300px theme-light-show" alt="Return Home" />
                </div>
                <div className="mb-0">
                  <div className="btn btn-sm btn-primary" onClick={returnHome}>
                    <span>Return Home</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}
