import React, {FC} from 'react'
import _ from 'lodash'
import INewsItem from '../../../../@types/newsItem'

type Props = {
  newsLink: string
  description: string | any
  descriptionTranslated?: string | any
  row?: INewsItem
  capturedWords?: string | null
}

const DescriptionCell: FC<Props> = ({
  newsLink,
  description,
  descriptionTranslated,
  capturedWords,
}) => {
  const highlightDescription = () => {
    const textToHighlight = descriptionTranslated || description

    if (capturedWords) {
      const parts = textToHighlight.split(new RegExp(`(${capturedWords})`, 'gi'))

      const highlighted = parts.map((part: any, i: any) =>
        part.toLowerCase() === capturedWords.toLowerCase() ? (
          <mark
            key={i}
            className='mark'
            style={{fontSize: 12, fontWeight: 'bolder', backgroundColor: '#fff'}}
          >
            {part}
          </mark>
        ) : (
          <span key={i}>{part}</span>
        )
      )

      return <React.Fragment>{highlighted}</React.Fragment>
    }

    return <span>{textToHighlight}</span>
  }

  const renderDescription = () => {
    if (!description || description === '') {
      return <span>No Description Found</span>
    }

    return (
      <>
        {highlightDescription()}
        <a
          href={newsLink}
          target='_blank'
          rel='noopener noreferrer'
          style={{
            fontWeight: 'bolder',
            color: '#181c32',
          }}
        >
          {' '}
          Read More
        </a>
      </>
    )
  }

  return (
    <div className='text-muted fw-bold mt-1' style={{fontSize: 12}}>
      {renderDescription()}
    </div>
  )
}

export {DescriptionCell}
