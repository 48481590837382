import { KTCard } from '../../../../_metronic/helpers'
import DashboardNewsTable from '../components/table/DashboardNewsTable'
import {ListViewProvider} from '../core/ListViewProvider'
import {QueryRequestProvider} from '../core/QueryRequestProvider'
import {QueryResponseProvider} from '../core/QueryResponseProvider'

const DashboardNewsList = () => {
  return (
    <>
      <KTCard>
      <DashboardNewsTable />
        <div />
      </KTCard>
    </>
  )
}

const CustomListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <DashboardNewsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {CustomListWrapper}
