import React, {useState, useEffect} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {toast} from 'react-toastify'
import PasswordStrengthBar from '../../components/PasswordStrengthBar'
import {useAuth} from '../auth'
import SelectDropdown from '../filterNews/SelectDropdown'
import UserAddForAdmin from '../../../@types/userAddForAdmin'
import Role from '../../../@types/role'
import {authConst, roleConst} from '../../helpers/Constant'
import {getList, postObject} from '../auth/core/_requests'

export function UserAdd() {
  const [loading, setLoading] = useState(false)
  const [roles, setRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState<number>()
  const [inputEmail, setInputEmail] = useState('')
  const {currentUser} = useAuth()
  const [showPassword, setShowPassword] = useState(false)

  const API_URL = `${authConst.registrationForAdminOnly}?adminId=${currentUser?.userId}`

  const [mails] = useState(['@property.tech', '@multi.eu'])
  const [passwordRequirements, setPasswordRequirements] = useState([
    {
      id: 1,
      description: 'Must be at least 8 characters long',
      isMet: false,
    },
    {
      id: 2,
      description: 'Must contain at least 1 number',
      isMet: false,
    },
    {
      id: 3,
      description: 'Must contain at least 1 lowercase letter',
      isMet: false,
    },
    {
      id: 4,
      description: 'Must contain at least 1 uppercase letter',
      isMet: false,
    },
    {
      id: 5,
      description: 'Must contain at least 1 special character',
      isMet: false,
    },
  ])

  useEffect(() => {
    getList(roleConst.getAll, {pageNumber: 0, pageSize: 0}).then((res) => setRoles(res.data.data))
  }, [])

  const handleLabelClick = (domain: string) => {
    setInputEmail((prevEmail) => {
      const emailParts = prevEmail.split('@')
      if (emailParts.length === 2) {
        setInputEmail(emailParts[0] + domain)
        return emailParts[0] + domain
      } else {
        return prevEmail + domain
      }
    })
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === '@') {
      event.preventDefault()
    }
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault()
  }

  const rolesOption = roles.map((role: Role, index) => ({
    key: index,
    text: role.name,
    value: role.id,
  }))

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    roleId: 0,
  }

  const validationScheme = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    password: Yup.string()
      .required('Password is required')
      .matches(/[0-9]/, 'Password requires a number')
      .matches(/[a-z]/, 'Password requires a lowercase letter')
      .matches(/[A-Z]/, 'Password requires an uppercase letter')
      .matches(/[^\w]/, 'Password requires a symbol'),
    roleId: Yup.number().required(),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: validationScheme,
    onSubmit: (values, {resetForm, setSubmitting}) => {
      setTimeout(() => {
        const userAddObj: UserAddForAdmin = {
          firstName: formik.values.firstName,
          lastName: formik.values.lastName,
          email: inputEmail,
          password: formik.values.password,
          roleId: selectedRole,
        }
        setLoading(true)
        postObject(API_URL, userAddObj)
          .then((res) => {
            resetForm()
            setInputEmail('')
            setSelectedRole(undefined)
            setLoading(false)
            toast.success(res.data.message, {autoClose: 1000})
            setTimeout(() => {
              window.location.reload()
            }, 2500)
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
          })
      }, 100)
    },
  })

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const checkPasswordRequirements = (value: string) => {
    const updatedRequirements = [...passwordRequirements]
    updatedRequirements.forEach((requirement) => {
      switch (requirement.id) {
        case 1:
          requirement.isMet = value.length >= 8
          break
        case 2:
          requirement.isMet = /\d/.test(value)
          break
        case 3:
          requirement.isMet = /[a-z]/.test(value)
          break
        case 4:
          requirement.isMet = /[A-Z]/.test(value)
          break
        case 5:
          requirement.isMet = /[!@#$%^&*]/.test(value)
          break
        default:
          break
      }
    })
    return updatedRequirements
  }

  const handleRoleSelectChange = (selected: number) => {
    setSelectedRole(selected)
    formik.setFieldValue('roleId', selected)
  }

  return (
    <>
      <form className='form w-100' onSubmit={formik.handleSubmit}>
        {formik.status ? (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        ) : null}

        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>First Name</label>
          <input
            placeholder='First Name'
            {...formik.getFieldProps('firstName')}
            className={clsx('form-control form-control-lg form-control-solid', {
              'is-invalid': formik.touched.firstName && formik.errors.firstName,
            })}
            type='text'
            name='firstName'
            autoComplete='off'
          />
          {formik.touched.firstName && formik.errors.firstName && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.firstName}</span>
            </div>
          )}
        </div>

        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Last Name</label>
          <input
            placeholder='Last Name'
            {...formik.getFieldProps('lastName')}
            className={clsx('form-control form-control-lg form-control-solid', {
              'is-invalid': formik.touched.lastName && formik.errors.lastName,
            })}
            type='text'
            name='lastName'
            autoComplete='off'
          />
          {formik.touched.lastName && formik.errors.lastName && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.lastName}</span>
            </div>
          )}
        </div>

        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
          <input
            type='text'
            className='form-control mb-5'
            placeholder='Email'
            onKeyPress={handleKeyPress}
            onPaste={handlePaste}
            value={inputEmail}
            onChange={(event) => {
              const text = event.target.value
              if (!text.includes('@')) {
                setInputEmail(event.target.value)
              }
            }}
          />
          <div className='input-group-append'>
            {mails.map((mail) => (
              <span
                className='input-group-text'
                id='basic-addon2'
                onClick={() => handleLabelClick(mail)}
              >
                <span>{mail}</span>
              </span>
            ))}
          </div>
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          )}
        </div>

        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Password</label>
          <div className='input-group'>
            <input
              placeholder='Password'
              type={showPassword ? 'text' : 'password'}
              name='password'
              autoComplete='new-password'
              onChange={(event) => {
                const value = event.target.value
                const updatedRequirements = checkPasswordRequirements(value)
                setPasswordRequirements(updatedRequirements)
                formik.handleChange(event)
              }}
              onBlur={formik.handleBlur}
              className={clsx('form-control form-control-lg form-control-solid', {
                'is-invalid': formik.touched.password && formik.errors.password,
              })}
            />
            <button type='button' className='btn btn-light' onClick={toggleShowPassword}>
              <i
                className={`bi bi-eye${showPassword ? '-slash' : ''}`}
                style={{cursor: 'pointer'}}
              ></i>
            </button>
          </div>
          <ul className='password-requirements'>
            {passwordRequirements.map((requirement) => (
              <li
                key={requirement.id}
                style={{
                  color: requirement.isMet ? 'green' : 'red',
                  fontWeight: requirement.isMet ? 'bold' : 'normal',
                }}
              >
                {requirement.description}
              </li>
            ))}
          </ul>

          <PasswordStrengthBar password={formik.values.password} />
          {formik.touched.password && formik.errors.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <ul className='error-list'>
                {formik.errors.password.split(', ').map((error, index) => (
                  <li key={index} className='error-item'>
                    {error}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Roles</label>
          <SelectDropdown
            multiple={false}
            options={rolesOption}
            onChange={handleRoleSelectChange}
            selectedOptions={selectedRole}
            hasText={true}
            placeHolder='Role'
          />
          {formik.errors.roleId && formik.touched.roleId && (
            <div className={'ui pointing red basic label'}>{formik.errors.roleId}</div>
          )}
        </div>

        <div className='text-center'>
          <button type='submit' className='btn btn-lg btn-primary w-100 mb-5' disabled={loading}>
            {!loading ? (
              'Create User'
            ) : (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </>
  )
}
