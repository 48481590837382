import { FC, lazy, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { EmailAdd } from '../modules/emailModules/EmailAdd'
import { WordCreate } from '../modules/wordModules/WordCreate'
import { RoleAdd } from '../modules/roleModules/RoleAdd'
import { ProfileDetails } from '../modules/profile/settings/ProfileDetails'
import { UserAdd } from '../modules/userModules/UserAdd'
import { NewsResourceAdd } from '../modules/newsResourceModule/newsResource/NewsResourceAdd'
import { NewsResourceMakeRequest } from '../modules/newsResourceModule/newsResourceRequest/NewsResourceMakeRequest'
import DashboardNewsPage from '../pages/dashboardNews/DashboardNewsPage'

const NewsSourcePage = lazy(
  () => import('../modules/newsResourceModule/newsResource/NewsSourcePage')
)
const SavedWordsPage = lazy(() => import('../modules/wordModules/SavedWordsPage'))
const RolesPage = lazy(() => import('../modules/roleModules/RolesPage'))
const MailRecipientPage = lazy(() => import('../modules/emailModules/MailRecipientPage'))
const UserListPage = lazy(() => import('../modules/userModules/UserListPage'))
const SavedNewsPage = lazy(() => import('../modules/newsModules/savedNews/SavedNewsPage'))
const LiveNewsPage = lazy(() => import('../modules/newsModules/liveNews/LiveNewsPage'))
const FavoriteNewsPage = lazy(() => import('../modules/profile/favorite-news/FavoriteNewsPage'))
const NewsSourceRequestPage = lazy(
  () => import('../modules/newsResourceModule/newsResourceRequest/NewsSourceRequestPage')
)
const NewsSourceRequestPendingPage = lazy(
  () => import('../modules/newsResourceModule/newsResourcePending/NewsResourcePendingPage')
)

const PrivateRoutes = () => {
  return ( 
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/news' />} />
        {/* Pages */}

        <Route
          path='/news-resources'
          element={
            <SuspensedView>
              <NewsSourcePage />
            </SuspensedView>
          }
        />

        <Route
          path='/news-resource-requests'
          element={
            <SuspensedView>
              <NewsSourceRequestPage />
            </SuspensedView>
          }
        />

        <Route
          path='/pending-requests'
          element={
            <SuspensedView>
              <NewsSourceRequestPendingPage />
            </SuspensedView>
          }
        />

        <Route 
          path='/news-resource-make-request'
          element={
            <SuspensedView>
              <NewsResourceMakeRequest />
            </SuspensedView>
          }
        />

        <Route
          path='/news-resource-add'
          element={
            <SuspensedView>
              <NewsResourceAdd />
            </SuspensedView>
          }
        />

        <Route
          path='saved-news/*'
          element={
            <SuspensedView>
              <SavedNewsPage />
            </SuspensedView>
          }
        />

        <Route
          path='live-news/*'
          element={
            <SuspensedView>
              <LiveNewsPage />
            </SuspensedView>
          }
        />

        <Route
          path='/*'
          element={
            <SuspensedView>
              <DashboardNewsPage />
            </SuspensedView>
          }
        />

        <Route
          path='saved-words/*'
          element={
            <SuspensedView>
              <SavedWordsPage />
            </SuspensedView>
          }
        />

        <Route
          path='word-create/*'
          element={
            <SuspensedView>
              <WordCreate />
            </SuspensedView>
          }
        />

        <Route
          path='email-list/*'
          element={
            <SuspensedView>
              <MailRecipientPage />
            </SuspensedView>
          }
        />

        <Route
          path='email-add/*'
          element={
            <SuspensedView>
              <EmailAdd />
            </SuspensedView>
          }
        />

        <Route
          path='user-list/*'
          element={
            <SuspensedView>
              <UserListPage />
            </SuspensedView>
          }
        />

        <Route
          path='user-add/*'
          element={
            <SuspensedView>
              <UserAdd />
            </SuspensedView>
          }
        />

        <Route
          path='/profile/settings/*'
          element={
            <SuspensedView>
              <ProfileDetails />
            </SuspensedView>
          }
        />

        <Route
          path='/profile/favorite-news/*'
          element={
            <SuspensedView>
              <FavoriteNewsPage />
            </SuspensedView>
          }
        />

        <Route
          path='role-list/*'
          element={
            <SuspensedView>
              <RolesPage />
            </SuspensedView>
          }
        />

        <Route
          path='role-add/*'
          element={
            <SuspensedView>
              <RoleAdd />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
