/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from 'react'
import { KTCardBody, KTSVG } from '../../../../../_metronic/helpers'
import _ from 'lodash'
import { useQueryResponseData, useQueryResponseLoading } from '../../core/QueryResponseProvider'
import { ColumnInstance, Row, useTable } from 'react-table'
import { dashboardNewsTableColumn } from './columns/_columns'
import { CustomHeaderColumn } from './columns/CustomHeaderColumn'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { CustomRow } from './CustomRow'
import PaginationComponent from '../Pagination'
import { SearchComponent } from '../HeaderTop'
import NewsItem from '../../../../../@types/newsItem'
import NewsLoadingAnimation from '../../../../components/NewsLoadingAnimation'
import INewsItem from '../../../../../@types/newsItem'
import { handleSearch } from '../../../../../_metronic/helpers/filterHelper'
import { useAuth } from '../../../../modules/auth'
import { favoriteNewsConst } from '../../../../helpers/Constant'
import { get } from '../../../../modules/auth/core/_requests'
import { useNavigate } from 'react-router-dom'


const DashboardNewsTable = () => {

  const responseData = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const { state } = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState("")
  const [rows, setRows] = useState<any>(null)
  const data = useMemo(() => state.isFiltered ? responseData.data || [] : responseData || [], [state.isFiltered ? responseData.data || [] : responseData.data || []])
  const columns = useMemo(() => dashboardNewsTableColumn, [])
  const [favoriteNews, setFavoriteNews] = useState<string[]>([]);
  const table = useTable({
    columns,
    data,
  })

  const navigate = useNavigate()
  const { currentUser } = useAuth();
  const [allData, setAllData] = useState<any>(null)
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber);
  };


  useEffect(() => {
    const ex = table.rows;
    const searchedData = handleSearch(ex, searchTerm, "title", "description");
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = searchedData.slice(startIndex, endIndex);
    setAllData(searchedData);
    setRows(currentData);
  }, [searchTerm, state, currentPage, isLoading]);

  useEffect(() => {
    getFavoriteNewsTitles()
  }, [currentUser]);

  const getFavoriteNewsTitles = () => {
    if (currentUser && responseData) {
      get(`${favoriteNewsConst.getFavoriteNewsTitlesByUserId}?userId=${currentUser?.userId}`).
        then((res) => {
          setFavoriteNews(res.data)
        })
    }
  }

  const manipulateDataRows = (data: INewsItem) => {
    const updatedRows = [...table.rows]
    const newsIndex = updatedRows.findIndex((n) => n.original.title === data.title)
    updatedRows[newsIndex].original = data
    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage
    const currentData = updatedRows.slice(startIndex, endIndex)
    setAllData(updatedRows)
    setRows(currentData)
  }


  if (isLoading) return (
    <NewsLoadingAnimation />
  )
  return (
    <>
      <div className='text-center mt-10'>
        <h1>News</h1>
        <h6 style={{ fontSize: '12px' }}>(List of captured news 10 minutes ago)</h6>
        <button className='btn btn-dark btn-hover-scale' onClick={() => navigate('/live-news')}>
          <KTSVG path='/media/icons/live.svg' className='svg-icon-2' />
          Get Live News
        </button>
      </div>
      {table.rows.length > 0 && <SearchComponent isFiltered={state.isFiltered} setSearchTerm={setSearchTerm} setCurrentPage={setCurrentPage} />}
      <KTCardBody className='py-4'>
        <div className='table-responsive' style={{ marginTop: 20 }}>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            {...table.getTableProps()}
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {table.headers.map((column: ColumnInstance<NewsItem>) => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...table.getTableBodyProps()}>
              {((rows && rows.length > 0)) ? (
                rows.map((row: Row<NewsItem>, i: any) => {
                  table.prepareRow(row)
                  const isFavorite = favoriteNews?.includes(row.original?.title);
                  return <CustomRow row={row} key={`row-${i}-${row.id}-${row.original.title}`}
                    setNewsArray={manipulateDataRows} isFavorite={isFavorite} />
                })
              ) : (searchTerm.length > 0) ?
                <tr>
                  <td colSpan={12}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
                :
                table.rows.length > 0 ? (
                  table.rows.map((row: Row<NewsItem>, i) => {
                    table.prepareRow(row)
                    const isFavorite = favoriteNews?.includes(row.original?.title);
                    return <CustomRow row={row} key={`row-${i}-${row.id}-${row.original.title}`}
                      setNewsArray={manipulateDataRows} isFavorite={isFavorite} />
                  })
                ) : (
                  <tr>
                    <td colSpan={12}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )
              }
            </tbody>
          </table>
        </div>
        {
          table.rows.length > 0 &&
          <PaginationComponent
          itemsPerPage={itemsPerPage}
          dataLength={allData?.length}
          onPageChange={handlePageChange}
          currentPageNumber={currentPage}
        />
        }
      </KTCardBody>
    </>
  )
}



export default DashboardNewsTable;