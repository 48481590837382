import { FC } from 'react'
import { DateFormatter } from '../../../helpers/DateFormatter'

type Props = {
  date: string | any
}

const DateCell: FC<Props> = ({ date }) => (
  <div className='badge badge-light fw-bolder'>
    {DateFormatter(date)}
  </div>
)

export { DateCell }
