import axios, { AxiosError } from 'axios'
import {AuthModel, UserModel} from './_models'
import {environment} from '../../../services/environment.prod'
import IPagination from '../../../../@types/pagination'

export const GET_USER_BY_ACCESSTOKEN_URL = `${environment.apiUrl}Auth/verifyToken`
// export const LOGIN_URL = `${environment.apiUrl}Auth/login`
export const LOGIN_URL = `Auth/login`
export const REGISTER_URL = `${environment.apiUrl}Auth/register`

// Server should return AuthModel
export function login(email: string, password: string, rememberMe: boolean) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
    rememberMe,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstName: string,
  lastName: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    firstName: firstName,
    lastName: lastName,
    password,
    password_confirmation,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(`${environment.apiUrl}Auth/verifyToken?token=${token}`, {
    token: token,
  })
}

export function deleteObject(data: any, objectType: string) {
  let deleteUrl = ''

  deleteUrl = `${environment.apiUrl}${objectType}`
  return axios.post<any>(deleteUrl, data)
}

export function postObject(url: string, body: any) {
  return axios
    .post<any>(url, body)
    .then((response: any) => {
      return response
    })
    .catch((error: AxiosError) => {
      return error
    })
}

export function getList(url: string, pagination: IPagination) {
  return axios.post(url, pagination)
}

export function get(url: string) {
  return axios.get(url)
}
 