import clsx from 'clsx'
import { FC, PropsWithChildren } from 'react'
import { HeaderProps } from 'react-table'
import NewsItem from '../../../../../../@types/newsItem'


type Props = {
  className?: string
  title?: string
  tableProps: PropsWithChildren<HeaderProps<NewsItem>>
}
const CustomHeader: FC<Props> = ({ className, title, tableProps }) => {
  const id = tableProps.column.id

  const sortColumn = () => {
    // avoid sorting for these columns
    if (id === 'actions') {
      return
    }

  }

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(
        className,
      )}
      style={{ cursor: 'pointer' }}
      onClick={sortColumn}
    >
      {title}
    </th>
  )
}

export { CustomHeader }
