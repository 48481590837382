/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {CheckRole} from '../../../helpers/CheckRoles'
import { adminRoutes, userRoutes } from '../../../../app/routing/routes'

export function AsideMenuMain() {
  const isAdmin = CheckRole('Admin')

  const selectedRoutes = isAdmin ? adminRoutes : userRoutes

  return (
    <>
      {
          selectedRoutes.map((route, key)=>(
          <AsideMenuItemWithSub to={route.to} title={route.title} fontIcon={route.fontIcon} key={key}>
            {route.hasSub && route.hasSub.map((sub, k)=>(
              <AsideMenuItem to={sub.to} title={sub.title} hasBullet={true} key={k} />
            ))}
          </AsideMenuItemWithSub>
        ))
      }
    </>
  )
}
