import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { useAuth } from '../auth';
import { postObject } from '../auth/core/_requests';
import { savedWordConst } from '../../helpers/Constant';


export function WordCreate() {
  
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false)

  const wordAddSchema = Yup.object().shape({
    word: Yup.string().min(3, 'Minimum 3 symbols').required('Word is required'),
  });

  const initialValues = {
    userId: currentUser?.id,
    word: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: wordAddSchema,
    onSubmit: (values, {resetForm, setSubmitting}) => {
      setTimeout(() => {
        setLoading(true)
        postObject(savedWordConst.add, values)
          .then((res) => {
            toast.success(res.data.message);
            resetForm();
            setLoading(false)
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
          })
      }, 100)
    },
  });

  return (
    <>
      <form className='form w-100' onSubmit={formik.handleSubmit}>
        {formik.status ? (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        ) : null}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Word</label>
          <input
            placeholder='Word'
            {...formik.getFieldProps('word')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.word && formik.errors.word,
              },
              {
                'is-valid': formik.touched.word && !formik.errors.word,
              }
            )}
            type='text'
            name='word'
            autoComplete='off'
          />
          {formik.touched.word && formik.errors.word && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.word}</span>
            </div>
          )}
        </div>

        <div className='text-center'>
        <button
            type='submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={loading}
          >
            {!loading ? (
              'Word Create'
            ) : (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </>
  );
}
