import React, {useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import 'yup-phone'
import {toast} from 'react-toastify'

import {useAuth} from '../../auth'
import clsx from 'clsx'
import { authConst } from '../../../helpers/Constant'
import { postObject } from '../../auth/core/_requests'

export default function PasswordChange() {

  const {currentUser} = useAuth()

  const [loading, setLoading] = useState(false)
  const [showCurrentPassword, setCurrentShowPassword] = useState(false)
  const [showNewPassword, setNewShowPassword] = useState(false)

  const initialValues = {
    userId: currentUser?.userId,
    currentPassword: '',
    newPassword: '',
    newPasswordValidate: '',
  }

  const profileDetailsSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Current password is required'),
    newPassword: Yup.string()
      .required('New password is required')
      .min(8)
      .matches(/[0-9]/, 'Password requires a number')
      .matches(/[a-z]/, 'Password requires a lowercase letter')
      .matches(/[A-Z]/, 'Password requires an uppercase letter')
      .matches(/[^\w]/, 'Password requires a symbol'),
    newPasswordValidate: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: profileDetailsSchema,
    enableReinitialize: true,
    onSubmit: (values, {resetForm}) => {
      setLoading(true)
      setTimeout(() => {
        postObject(authConst.changePassword, values)
        
        .then((result) => {
          toast.info(result.data.message)
          resetForm()
        })
        setLoading(false)
      }, 1000)
    },
  })

  const toggleCurrentShowPassword = () => {
    setCurrentShowPassword(!showCurrentPassword)
  }

  const toggleNewShowPassword = () => {
    setNewShowPassword(!showNewPassword)
  }

  return (
    <div>
      <div className='card-header border-0'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Password</h3>
        </div>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label required fw-bold fs-6'>Current Password</label>
          <div className='col-lg-8 fv-row'>
            <div className='input-group'>
              <input
                placeholder='Password'
                type={showCurrentPassword ? 'text' : 'password'}
                name='currentPassword'
                autoComplete='off'
                value={formik.values.currentPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={clsx('form-control form-control-lg form-control-solid', {
                  'is-invalid': formik.touched.currentPassword && formik.errors.currentPassword,
                })}
              />
              <button type='button' className='btn btn-light' onClick={toggleCurrentShowPassword}>
                <i
                  className={`bi bi-eye${showCurrentPassword ? '-slash' : ''}`}
                  style={{cursor: 'pointer'}}
                ></i>
              </button>
            </div>
            {formik.touched.currentPassword && formik.errors.currentPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.currentPassword}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>New Password</span>
          </label>

          <div className='col-lg-8 fv-row'>
            <div className='input-group'>
              <input
                placeholder='New Password'
                type={showNewPassword ? 'text' : 'password'}
                name='newPassword'
                autoComplete='off'
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={clsx('form-control form-control-lg form-control-solid', {
                  'is-invalid': formik.touched.newPassword && formik.errors.newPassword,
                })}
              />
              <button type='button' className='btn btn-light' onClick={toggleNewShowPassword}>
                <i
                  className={`bi bi-eye${showNewPassword ? '-slash' : ''}`}
                  style={{cursor: 'pointer'}}
                ></i>
              </button>
            </div>
            {formik.touched.newPassword && formik.errors.newPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.newPassword}</div>
              </div>
            )}
          </div>
        </div>
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Verify the new password</span>
          </label>

          <div className='col-lg-8 fv-row'>
            <input
              type={showNewPassword ? 'text' : 'password'}
              className='form-control form-control-lg form-control-solid'
              placeholder='Verify New Password'
              {...formik.getFieldProps('newPasswordValidate')}
            />
            {formik.touched.newPasswordValidate && formik.errors.newPasswordValidate && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.newPasswordValidate}</div>
              </div>
            )}
          </div>
        </div>
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading}>
            {!loading && 'Save Changes'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}
