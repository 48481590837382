import React, {useState, useEffect} from 'react'
import {toast} from 'react-toastify'
import { avatarConst } from '../../../helpers/Constant'
import {useAuth} from '../../auth'
import { get, getList } from '../../auth/core/_requests'

interface AvatarChangeProps {
  setShowIsAvatars: React.Dispatch<React.SetStateAction<boolean>>
}

export default function AvatarChange({setShowIsAvatars}: AvatarChangeProps) {
  const [avatarURLs, setAvatarURLs] = useState<string[]>([])
  const {currentUser, setCurrentUser} = useAuth()

  useEffect(() => {
    const fetchAvatarURLs = async () => {
      try {
        const response = (await getList(avatarConst.getAllAvatars, {pageNumber:0, pageSize:0}))
        setAvatarURLs(response.data)
      } catch (error) {
        console.error(error)
      }
    }

    fetchAvatarURLs()
  }, [])

  const changeAvatar = (avatarURL: string) => {
    const API_URL = `${avatarConst.changeAvatar}?userId=${currentUser?.userId}&pathName=${avatarURL}`
  
    currentUser &&
      get(API_URL).then((res) => {
        setShowIsAvatars(false)
        setCurrentUser((prevUser: any) => ({
          ...prevUser,
          avatarURL: avatarURL,
        }))
        toast.info(res.data.message)
      })
  }

  return (
    <div style={{display: 'flex'}}>
      {avatarURLs.map((avatarURL, key) => (
        <div key={key}>
          <img
            src={avatarURL}
            width='100px'
            style={{cursor: 'pointer'}}
            onClick={() => changeAvatar(avatarURL)}
          />
        </div>
      ))}
    </div>
  )
}
