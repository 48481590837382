import { FC, useState, createContext, useContext } from 'react'
import {
    initialModalView,
    ModalViewContextProps,
} from '../../_metronic/helpers'
import { ChildProps } from '../../_metronic/layout/core'


const ModalViewContext = createContext<ModalViewContextProps>(initialModalView)

const ModalViewProvider: FC<ChildProps> = ({ children }) => {

    const [title, setTitle] = useState<string>(initialModalView.title)
    const [description, setDescription] = useState<string>(initialModalView.description)
    const [show, setShow] = useState<boolean>(initialModalView.show)
    const [loading, setLoading] = useState<boolean>(initialModalView.loading)
    const [failed, setFailed] = useState<boolean>(initialModalView.failed)
    const [isDelete, setIsDelete] = useState<boolean>(initialModalView.isDelete)
    const [isUpdate, setIsUpdate] = useState<boolean>(initialModalView.isUpdate)
    const [actionUrl, setActionUrl] = useState<string>(initialModalView.actionUrl)
    const [id, setId] = useState<number>(initialModalView.id)
    const [userId, setUserId] = useState<any>(initialModalView.userId)
    const [modalObj, setModalObj] = useState<any>(initialModalView.modalObj)
    const [isNew, setIsNew] = useState<boolean>(initialModalView.isNew)
    const [isDeclined, setIsDeclined] = useState<boolean>(initialModalView.isDeclined)
    const [deleteTrigger, setDeleteTrigger] = useState<boolean>(initialModalView.deleteTrigger)
    const [type, setType] = useState<string>(initialModalView.type)
    const [backKey, setBackKey] = useState<string>(initialModalView.backKey)

    return (
        <ModalViewContext.Provider
            value={{
                id,
                actionUrl,
                title,
                description,
                show,
                loading,
                failed,
                isDelete,
                isDeclined,
                isUpdate,
                modalObj,
                isNew,
                userId,
                type,
                backKey,
                deleteTrigger,
                setDeleteTrigger,
                setTitle,
                setDescription,
                setShow,
                setLoading,
                setFailed,
                setIsDelete,
                setIsUpdate,
                setId,
                setIsDeclined,
                setUserId,
                setActionUrl,
                setModalObj,
                setIsNew,
                setType,
                setBackKey,
            }}
        >
            {children}
        </ModalViewContext.Provider>
    )
}

const useModalView = () => useContext(ModalViewContext)

export { ModalViewProvider, useModalView }
