import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { AuthModel, UserModel } from './_models'
import * as authHelper from './AuthHelpers'
import { get, getUserByToken } from './_requests'
import IRole from '../../../../@types/role'
import { userInfoConst, userRoleConst } from '../../../helpers/Constant'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  userRoles: IRole[]
  setUserRoles: Dispatch<SetStateAction<any>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => { },
  currentUser: undefined,
  setCurrentUser: () => { },
  userRoles: [],
  setUserRoles: () => { },
  logout: () => { },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}


const AuthProvider: FC = ({ children }) => {

  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [userRoles, setUserRoles] = useState<IRole[]>([])


  const saveAuth = (auth: AuthModel | undefined) => {
    if (auth) {
      authHelper.setAuth(auth)
      setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
    localStorage.clear()
  }

  return (
    <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, logout, setUserRoles, userRoles }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC = ({ children }) => {
  const { auth, logout, setCurrentUser, setUserRoles } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const { data } = await getUserByToken(apiToken)
          if (data) {
            const requestURL = `${userInfoConst.getUserInfo}?userId=${data.data.id}`
          await get(requestURL)
            .then(async (res) => {
              const getByUserIdRolesRequestURL = `${userRoleConst.getByUserIdRoles}?userId=${res.data.data.userId}` 
            await get(getByUserIdRolesRequestURL)
              .then((role => {
                setCurrentUser(res.data.data)
                setUserRoles(role.data)
                localStorage.setItem('userInfo', JSON.stringify(res.data))
              }))
            })
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.data.token) {
      requestUser(auth.data.token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
