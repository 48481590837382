import {Dispatch, SetStateAction} from 'react'
import IFilterNews from '../../../@types/filterNews'

export type ID = undefined | null | number

export type PaginationState = {
  page: number
  items_per_page: 10 | 30 | 50 | 100
  search: string
  isFiltered?: boolean
  filteredObj?: IFilterNews
  links?: Array<{label: string; active: boolean; url: string | null; page: number | null}>
}

export type SortState = {
  sort?: string
  order?: 'asc' | 'desc'
}

export type FilterState = {
  filter?: unknown
}

export type SearchState = {
  search?: string
}

export type Response<T> = {
  data?: any
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  }
}

export type QueryState = PaginationState & SortState & FilterState & SearchState

export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export const initialQueryState: QueryState = {
  page: 1,
  items_per_page: 10,
  search: '',
  isFiltered: false,
  filteredObj: {},
}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},
}

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export const initialQueryResponse = {refetch: () => {}, isLoading: false, query: ''}

export type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  isAllSelected: boolean
  disabled: boolean
}

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  setItemIdForUpdate: () => {},
  isAllSelected: false,
  disabled: false,
}

export type ModalViewContextProps = {
  id: number
  userId: any
  actionUrl: string
  title: string
  description: string
  show: boolean
  loading: boolean
  failed: boolean
  isDelete: boolean
  isUpdate: boolean
  isDeclined: boolean
  isNew: boolean
  modalObj: any
  type: string
  backKey: string
  deleteTrigger: boolean
  setTitle: Dispatch<SetStateAction<string>>
  setDescription: Dispatch<SetStateAction<string>>
  setShow: Dispatch<SetStateAction<boolean>>
  setLoading: Dispatch<SetStateAction<boolean>>
  setFailed: Dispatch<SetStateAction<boolean>>
  setIsDelete: Dispatch<SetStateAction<boolean>>
  setIsUpdate: Dispatch<SetStateAction<boolean>>
  setActionUrl: Dispatch<SetStateAction<string>>
  setId: Dispatch<SetStateAction<number>>
  setUserId: Dispatch<SetStateAction<any>>
  setIsNew: Dispatch<SetStateAction<boolean>>
  setIsDeclined: Dispatch<SetStateAction<boolean>>
  setModalObj: Dispatch<SetStateAction<any>>
  setType: Dispatch<SetStateAction<string>>
  setBackKey: Dispatch<SetStateAction<string>>
  setDeleteTrigger: Dispatch<SetStateAction<boolean>>
}

export const initialModalView: ModalViewContextProps = {
  id: 0,
  actionUrl: '',
  userId: '',
  title: '',
  description: '',
  show: false,
  loading: false,
  failed: false,
  isDelete: false,
  isUpdate: false,
  isDeclined: false,
  isNew: false,
  deleteTrigger: false,
  modalObj: {},
  type: '',
  backKey: '',
  setTitle: () => {},
  setDescription: () => {},
  setShow: () => {},
  setLoading: () => {},
  setFailed: () => {},
  setIsDelete: () => {},
  setIsUpdate: () => {},
  setActionUrl: () => {},
  setId: () => {},
  setUserId: () => {},
  setModalObj: () => {},
  setIsNew: () => {},
  setIsDeclined: () => {},
  setType: () => {},
  setBackKey: () => {},
  setDeleteTrigger: () => {},
}
