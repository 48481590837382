import { useEffect, useState } from 'react'
import Pagination from '@mui/material/Pagination'

export default function PaginationComponent({
  itemsPerPage,
  dataLength,
  onPageChange,
  currentPageNumber,
}: any) {

  const [inputPage, setInputPage] = useState(currentPageNumber)
  const [currentPage, setCurrentPage] = useState(currentPageNumber)
  const totalPages = Math.ceil(dataLength / itemsPerPage)


  useEffect(() => {
    setInputPage(currentPageNumber);
    setCurrentPage(currentPageNumber)
  }, [currentPageNumber]);

  const handlePageChange = (pageNumber: any) => {
    setCurrentPage(pageNumber)
    onPageChange(pageNumber)
  }

  const handleInputPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (parseInt(event.target.value, 10) < 1) {
      setInputPage(1);
    } else if (parseInt(event.target.value, 10) > totalPages) {
      setInputPage(totalPages)
    }
    else {
      setInputPage(parseInt(event.target.value, 10));
    }
  };

  const handleGoToPage = () => {
    setCurrentPage(inputPage)
    onPageChange(inputPage)
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Pagination
        page={currentPage}
        count={totalPages}
        color='primary'
        onChange={(_, page) => handlePageChange(page)}
      />
      <input
        type='number'
        min='1'
        max={totalPages}
        value={inputPage}
        onChange={handleInputPageChange}
        className='form-control ml-3 mr-3'
        style={{ width: '75px' }}
      />
      <button className='btn btn-primary m-4' onClick={handleGoToPage}>
        Go
      </button>
    </div>
  )
}
