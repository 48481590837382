import React, { FC } from 'react';
import _ from 'lodash';
import INewsItem from '../../../../@types/newsItem';

type Props = {
  title: string | any;
  translatedTitle?: string | any;
  row?: INewsItem;
  capturedWords?: string | null;
};

const TitleCell: FC<Props> = ({ title, translatedTitle, capturedWords }) => {
  const highlightTitle = () => {
    const textToHighlight = translatedTitle || title;

    if (capturedWords) {
      const parts = textToHighlight.split(new RegExp(`(${capturedWords})`, 'gi'));

      const highlighted = parts.map((part: any, i: any) =>
        part.toLowerCase() === capturedWords.toLowerCase() ? (
          <mark key={i} className="mark" style={{ fontSize: 12, fontWeight: 'bolder', backgroundColor: '#fff' }}>
            {part}
          </mark>
        ) : (
          <span key={i}>{part}</span>
        )
      );

      return <React.Fragment>{highlighted}</React.Fragment>;
    }

    return <span>{textToHighlight}</span>;
  };

  return (
    <div className='text-muted fw-bold mt-1' style={{ fontSize: 12 }}>
      {highlightTitle()}
    </div>
  );
};

export {TitleCell}