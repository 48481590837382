import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'

import { postObject } from '../core/_requests'
import { authConst } from '../../../helpers/Constant'

const resetPasswordSchema = Yup.object().shape({
  verificationCode: Yup.string()
    .required('Verification code is required')
    .matches(/^\d+$/, 'Only numbers are allowed in verification code')
    .min(6, 'Verification code must be at least 6 characters')
    .max(6, 'Verification code must be at most 6 characters'),
  newPassword: Yup.string()
    .required('New password is required')
    .min(8)
    .matches(/[0-9]/, 'Password requires a number')
    .matches(/[a-z]/, 'Password requires a lowercase letter')
    .matches(/[A-Z]/, 'Password requires an uppercase letter')
    .matches(/[^\w]/, 'Password requires a symbol'),
  newPasswordValidate: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
})

export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [statusMessage, setStatusMessage] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const navigate = useNavigate()

  const initialValues = {
    userEmail: window.location.href.split('userEmail=')[1],
    verificationCode: '',
    newPassword: '',
    newPasswordValidate: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        postObject(authConst.resetPassword, values)
          .then(({data}) => {
            setHasErrors(false)
            setLoading(false)
            setStatusMessage(data.message)
            if (data.success) {
              setTimeout(() => {
                navigate('/auth/login')
              }, 5000)
            }
          })
          .catch((err) => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
          })
      }, 1000)
    },
  })

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Reset Password</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>
            Reset your password using your verification code
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>{statusMessage}</div>
          </div>
        )}
        {/* end::Title */}

        {/* Verification Code */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Verification Code</label>
          <input
            type='text'
            maxLength={6}
            placeholder='Verification Code'
            autoComplete='off'
            {...formik.getFieldProps('verificationCode')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.verificationCode && formik.errors.verificationCode},
              {
                'is-valid': formik.touched.verificationCode && !formik.errors.verificationCode,
              }
            )}
          />
          {formik.touched.verificationCode && formik.errors.verificationCode && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.verificationCode}</span>
              </div>
            </div>
          )}
        </div>
        {/* Verification Code */}

        {/* New Password */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>New Password</label>
          <div className='input-group'>
            <input
              placeholder='Password'
              type={showPassword ? 'text' : 'password'}
              {...formik.getFieldProps('newPassword')}
              autoComplete='off'
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={clsx('form-control form-control-lg form-control-solid', {
                'is-invalid': formik.touched.newPassword && formik.errors.newPassword,
              })}
            />
            <button type='button' className='btn btn-light' onClick={toggleShowPassword}>
              <i
                className={`bi bi-eye${showPassword ? '-slash' : ''}`}
                style={{ cursor: 'pointer' }}
              ></i>
            </button>
          </div>
          {formik.touched.newPassword && formik.errors.newPassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.newPassword}</span>
              </div>
            </div>
          )}
        </div>
        {/* New Password */}

        {/* New Password Validate */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>New Password Validate</label>
            <input
              placeholder='New Password Validate'
              type={showPassword ? 'text' : 'password'}
              {...formik.getFieldProps('newPasswordValidate')}
              autoComplete='off'
              value={formik.values.newPasswordValidate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={clsx('form-control form-control-lg form-control-solid', {
                'is-invalid': formik.touched.newPasswordValidate && formik.errors.newPasswordValidate,
              })}
            />
          {formik.touched.newPassword && formik.errors.newPasswordValidate && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.newPasswordValidate}</span>
              </div>
            </div>
          )}
        </div>
        {/* New Password Validate */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
            disabled={loading}
          >
            {!loading ? (
              'Submit'
            ) : (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
            >
              Go to Login Page
            </button>
          </Link>
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
