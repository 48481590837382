import React, {useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {get, getUserByToken, login} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {Link} from 'react-router-dom'
import { userInfoConst, userRoleConst } from '../../../helpers/Constant'

export default function Login() {
  const {saveAuth, setCurrentUser, userRoles, setUserRoles} = useAuth()

  const [loading, setLoading] = useState(false)
  const [statusMessage, setStatusMessage] = useState('')
  const [rememberMe, setRememberMe] = useState(false)
  const [showPassword, setShowPassword] = useState(false)


  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required'),
  })

  const initialValues = {
    email: '',
    password: '',
    rememberMe: false,
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    enableReinitialize: true,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.email, values.password, rememberMe)
        if (auth.data !== null) {
          setStatusMessage(auth.message)
          saveAuth(auth)

          const {data: user} = await getUserByToken(auth.data.token)
          const getUserInfoRequestURL = `${userInfoConst.getUserInfo}?userId=${user.data.id}`
          await get(getUserInfoRequestURL).then(async (res) => {
            const getByUserIdRolesRequestURL = `${userRoleConst.getByUserIdRoles}?userId=${res.data.data.userId}` 
            get(getByUserIdRolesRequestURL)
            .then((role) => {
              setCurrentUser(res.data.data)
              setUserRoles(role.data)
              localStorage.setItem('userInfo', JSON.stringify(res.data))
            })
          })
        } else {
          setStatusMessage(auth.message)
          saveAuth(undefined)
        }
        setLoading(false)
      } catch (error: any) {
        setStatusMessage(error)
        console.error(error)
        saveAuth(undefined)
        setSubmitting(false)
        setLoading(false)
      } finally {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {statusMessage ? (
        <div className='mb-lg-15 alert alert-info'>
          <div className='alert-text font-weight-bold'>{statusMessage}</div>
        </div>
      ) : null}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bolder'
              style={{marginLeft: '5px'}}
            >
              Forgot Password ?
            </Link>
          </div>
        </div>
        <div className='input-group'>
          <input
            placeholder='Password'
            type={showPassword ? 'text' : 'password'}
            name='password'
            autoComplete='off'
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={clsx('form-control form-control-lg form-control-solid', {
              'is-invalid': formik.touched.password && formik.errors.password,
            })}
          />
          <button type='button' className='btn btn-light' onClick={toggleShowPassword}>
            <i
              className={`bi bi-eye${showPassword ? '-slash' : ''}`}
              style={{cursor: 'pointer'}}
            ></i>
          </button>
        </div>
        <label style={{ float: 'right', marginTop: 5 }}>
          <input
            style={{ marginRight: "5px" }}
            className='form-check-input'
            type='checkbox'
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          />
          Remember me
        </label>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.password}</span>
          </div>
        )}
      </div>

      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          // type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  )
}
