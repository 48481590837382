let apiUrl = ''

if (process.env.NODE_ENV === 'development') {
  // Development modunda yapılacak işlemler
  console.log('Geliştirme modunda çalışıyor')
  // apiUrl = 'https://localhost:7040/';
  apiUrl = 'https://multimedia-backend-dev.azurewebsites.net/'
  // apiUrl = 'https://multi-media-backend.azurewebsites.net/';
} else if (process.env.NODE_ENV === 'production') {
  // Production modunda yapılacak işlemler
  if (window.location.href.includes('-dev.')) {
    apiUrl = 'https://multimedia-backend-dev.azurewebsites.net/'
  } else {
    apiUrl = 'https://multi-media-backend.azurewebsites.net/'
  }
}

export const environment = {
  apiUrl: apiUrl
}
