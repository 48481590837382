import React from 'react';
import { Dropdown } from 'semantic-ui-react';

interface Option {
  value: string | number;
  text?: string;
}

interface SelectDropdownProps {
  options: Option[];
  selectedOptions: string | string[] | any | any[];
  onChange: (selectedOptions: string | string[] | any | any[]) => void;
  placeHolder?: string;
  multiple: boolean;
  className?: string;
  hasText?: boolean;
}

const SelectDropdown: React.FC<SelectDropdownProps> = ({
  options,
  selectedOptions,
  onChange,
  placeHolder,
  multiple,
  className,
  hasText,
}) => {
  const handleDropdownChange = (_: React.SyntheticEvent<HTMLElement>, data: any) => {
    const newSelectedOptions = data.value;
    onChange(newSelectedOptions);
  };

  const dropdownOptions = options.map((option) => ({
    value: option.value,
    text: hasText ? option.text : option.value,
  }));

  return (
    <div>
      <Dropdown
        placeholder={placeHolder}
        fluid
        multiple={multiple}
        search
        selection
        options={dropdownOptions}
        value={selectedOptions}
        onChange={handleDropdownChange}
        className={className}
      />
    </div>
  );
};

export default SelectDropdown;
