import React, {useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {toast} from 'react-toastify'
import countriesData from '../../../services/countries.json'
import languagesData from '../../../services/languages.json'
import SelectDropdown from '../../filterNews/SelectDropdown'
import NewsSourceAdd from '../../../../@types/newsSourceAdd'
import { postObject } from '../../auth/core/_requests'
import { newsResourceConst } from '../../../helpers/Constant'

export const NewsResourceAdd: React.FC = () => {

  const [loading, setLoading] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState<string>('')
  const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>()
  const [selectedLanguageCode, setSelectedLanguageCode] = useState<string | undefined>()
  const [selectedReadMethod, setSelectedReadMethod] = useState<number | undefined>()

  const initialValues = {
    newsSource: {
      newsSite: '',
      url: '',
      rssUrl: '',
      country: '',
      language: '',
      languageCode: '',
      readMethod: undefined,
    },
    xhrPaths: {
      containerXPath: '',
      titleXPath: '',
      descriptionXPath: '',
      publishDateXPath: '',
    },
  }

  const newsSourceSchema = Yup.object().shape({
    newsSite: Yup.string().required('Required'),
    url: Yup.string().required('Required'),
    rssUrl: Yup.string().when('readMethod', {
      is: 0,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    country: Yup.string().required('Required'),
    language: Yup.string().required('Required'),
    readMethod: Yup.number().required('Required'),
  })

  const xhrPathsSchema = Yup.object().shape({
    containerXPath: Yup.string().when('readMethod', {
      is: 1,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    titleXPath: Yup.string().when('readMethod', {
      is: 1,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    descriptionXPath: Yup.string().when('readMethod', {
      is: 1,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    publishDateXPath: Yup.string().when('readMethod', {
      is: 1,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      newsSource: newsSourceSchema,
      xhrPaths: xhrPathsSchema,
    }),
    validateOnChange: true,
    validateOnBlur: true,

    onSubmit: async (values, {resetForm, setSubmitting}) => {
      setTimeout(() => {
        const addObj:NewsSourceAdd = {
          newsSource:{
            newsSite: values.newsSource.newsSite,
            url: values.newsSource.url,
            rssUrl: values.newsSource.rssUrl,
            country: values.newsSource.country,
            language: values.newsSource.language,
            languageCode: values.newsSource.languageCode,
            readMethod: values.newsSource.readMethod
          },
          xhrPaths: {
            containerXPath: values.xhrPaths.containerXPath,
            titleXPath: values.xhrPaths.titleXPath,
            descriptionXPath: values.xhrPaths.descriptionXPath,
            publishDateXPath: values.xhrPaths.publishDateXPath
          }
        }
        setLoading(true)
          postObject(newsResourceConst.add, addObj)
          .then((res) => {
            toast.success(res.data.message)
            resetForm()
            setSelectedCountry('')
            setSelectedLanguage('')
            setSelectedLanguageCode('')
            setSelectedReadMethod(undefined)
            setLoading(false)
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
          })
      }, 100)
    },
  })

  const handleCountrySelect = (event: any) => {
    formik.setFieldValue('newsSource.country', event)
    setSelectedCountry(event)
  }

  const handleLanguageSelect = (event: any) => {
    const selectedLanguage = event
    const selectedLanguageCode = languagesData.find(
      (language) => language.value === selectedLanguage
    )

    formik.setFieldValue('newsSource.languageCode', selectedLanguageCode?.code)
    setSelectedLanguageCode(selectedLanguageCode?.code)
    setSelectedLanguage(selectedLanguageCode?.value)
    formik.setFieldValue('newsSource.language', event)
  }

  const handleReadMethodSelect = (event: any) => {
    const selectedReadMethod = parseInt(event)
    setSelectedReadMethod(isNaN(selectedReadMethod) ? undefined : selectedReadMethod)
    formik.setFieldValue(
      'newsSource.readMethod',
      isNaN(selectedReadMethod) ? undefined : selectedReadMethod
    )
  }

  const newsReadMethods = [
    {value: 0, text: 'Rss'},
    {value: 1, text: 'Xhr'},
  ]
  

  return (
    <>
      <form className='form w-100' onSubmit={formik.handleSubmit}>
        {formik.status ? (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        ) : null}

        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>News Site</label>
          <input
            placeholder='News Site'
            {...formik.getFieldProps('newsSource.newsSite')}
            className={clsx('form-control form-control-lg', {
              'is-invalid':
                formik.touched.newsSource?.newsSite && formik.errors.newsSource?.newsSite,
            })}
            type='text'
            name='newsSource.newsSite'
            autoComplete='off'
          />
          {formik.touched.newsSource?.newsSite && formik.errors.newsSource?.newsSite && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.newsSource?.newsSite}</span>
            </div>
          )}
        </div>

        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>URL</label>
          <input
            placeholder='Url'
            {...formik.getFieldProps('newsSource.url')}
            className={clsx('form-control form-control-lg', {
              'is-invalid': formik.touched.newsSource?.url && formik.errors.newsSource?.url,
            })}
            type='text'
            name='newsSource.url'
            autoComplete='off'
          />
          {formik.touched.newsSource?.url && formik.errors.newsSource?.url && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.newsSource?.url}</span>
            </div>
          )}
        </div>

        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Country</label>
          <SelectDropdown
            multiple={false}
            placeHolder='Select Country'
            onChange={handleCountrySelect}
            options={countriesData}
            selectedOptions={selectedCountry}
            className='form-control form-control-lg'
          />
          {formik.touched.newsSource?.country && formik.errors.newsSource?.country && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.newsSource?.country}</span>
            </div>
          )}
        </div>

        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Language</label>
          <SelectDropdown
            multiple={false}
            placeHolder='Select Language'
            onChange={handleLanguageSelect}
            options={languagesData}
            selectedOptions={selectedLanguage}
          />
          {formik.touched.newsSource?.language && formik.errors.newsSource?.language && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.newsSource?.language}</span>
            </div>
          )}
        </div>

        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>News Read Method</label>
          <SelectDropdown
            multiple={false}
            placeHolder='Select Read Method'
            onChange={handleReadMethodSelect}
            options={newsReadMethods}
            selectedOptions={selectedReadMethod}
            className='form-control form-control-lg'
            hasText={true}
          />

          {formik.touched.newsSource?.readMethod && formik.errors.newsSource?.readMethod && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.newsSource?.readMethod}</span>
            </div>
          )}
        </div>

        {selectedReadMethod === 0 && (
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>Rss URL</label>
            <input
              placeholder='Rss Url'
              {...formik.getFieldProps('newsSource.rssUrl')}
              className={clsx('form-control form-control-lg', {
                'is-invalid': formik.touched.newsSource?.rssUrl && formik.errors.newsSource?.rssUrl,
              })}
              type='text'
              name='newsSource.rssUrl'
              autoComplete='off'
            />
            {formik.touched.newsSource?.rssUrl && formik.errors.newsSource?.rssUrl && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.newsSource?.rssUrl}</span>
              </div>
            )}
          </div>
        )}

        {selectedReadMethod === 1 && (
          <div className='xpath-fields'>
            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='form-label fs-6 fw-bolder text-dark'>Container XPath</label>
                  <input
                    type='text'
                    className='form-control form-control-lg'
                    placeholder='Container XPath'
                    {...formik.getFieldProps('xhrPaths.containerXPath')}
                  />
                  {formik.touched.xhrPaths?.containerXPath &&
                    formik.errors.xhrPaths?.containerXPath && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          {formik.errors.xhrPaths?.containerXPath}
                        </div>
                      </div>
                    )}
                </div>

                <div className='col-lg-6 fv-row'>
                  <label className='form-label fs-6 fw-bolder text-dark'>Title XPath</label>
                  <input
                    type='text'
                    className='form-control form-control-lg'
                    placeholder='Title XPath'
                    {...formik.getFieldProps('xhrPaths.titleXPath')}
                  />
                  {formik.touched.xhrPaths?.titleXPath && formik.errors.xhrPaths?.titleXPath && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.xhrPaths?.titleXPath}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <label className='form-label fs-6 fw-bolder text-dark'>Description XPath</label>
                  <input
                    type='text'
                    className='form-control form-control-lg'
                    placeholder='Description XPath'
                    {...formik.getFieldProps('xhrPaths.descriptionXPath')}
                  />
                  {formik.touched.xhrPaths?.descriptionXPath &&
                    formik.errors.xhrPaths?.descriptionXPath && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          {formik.errors.xhrPaths?.descriptionXPath}
                        </div>
                      </div>
                    )}
                </div>

                <div className='col-lg-6 fv-row'>
                  <label className='form-label fs-6 fw-bolder text-dark'>Publish Date XPath</label>
                  <input
                    type='text'
                    className='form-control form-control-lg'
                    placeholder='Publish Date XPath'
                    {...formik.getFieldProps('xhrPaths.publishDateXPath')}
                  />
                  {formik.touched.xhrPaths?.publishDateXPath &&
                    formik.errors.xhrPaths?.publishDateXPath && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          {formik.errors.xhrPaths?.publishDateXPath}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className='text-center'>
          <button type='submit' className='btn btn-lg btn-primary w-100 mb-5' disabled={loading}>
            {!loading ? (
              'News Resource Create'
            ) : (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </>
  )
}
