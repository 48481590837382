import {FC} from 'react'
import _ from 'lodash'

type Props = {
  capturedWords: string | any
}

const CapturedWordsCell: FC<Props> = ({capturedWords}) => (
  <div className='mt-1'>
    <div className='mt-1 text-center' style={{fontSize:11}}>
      <span className='text-dark'>{capturedWords ? capturedWords : "-"}</span>
    </div>
  </div>
)

export {CapturedWordsCell}
