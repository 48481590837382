import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {CircularProgress} from '@mui/material'
import { authConst } from '../../../helpers/Constant'
import { get } from '../core/_requests'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {

  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [statusMessage, setStatusMessage] = useState('')
  const [remainingSeconds, setRemainingSeconds] = useState(0)
  const [initialSeconds, setInitialSeconds] = useState(300)

  useEffect(() => {
    let interval: NodeJS.Timeout

    if (remainingSeconds > 0) {
      interval = setInterval(() => {
        setRemainingSeconds((prevSeconds) => prevSeconds - 1)
      }, 1000)
    }
    return () => clearInterval(interval)
  }, [remainingSeconds])

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setHasErrors(undefined)
      setTimeout(() => {
        setLoading(true)
        const API_URL = `${authConst.generateVerificationCode}?userEmail=${values.email}`
        get(API_URL).then(({data}) => {
            setRemainingSeconds(data.data?.remainingTime)
            // setHasErrors(false)
            setLoading(false)
            setStatusMessage(data.message)
          })
          .catch((err) => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatusMessage('Something went wrong')
          })
      }, 100)
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Forgot Password ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>Enter your email to reset your password.</div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              {/* Sorry, looks like there are some errors detected, please try again. */}
              {statusMessage}
            </div>
          </div>
        )}

        {statusMessage && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>{statusMessage}</div>
          </div>
        )}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
          <input
            type='email'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {remainingSeconds ? (
          <div className='text-center mb-5'>
            <div style={{position: 'relative'}}>
              <CircularProgress
                variant='determinate'
                value={(remainingSeconds / initialSeconds) * 100}
                size={100}
              />
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  fontSize: '24px',
                  fontWeight: 'bold',
                }}
              >
                {remainingSeconds}
              </div>
            </div>
          </div>
        ) : null}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          {remainingSeconds === 0 && (
            <button
              type='submit'
              className='btn btn-lg btn-primary fw-bolder me-4'
              disabled={loading || remainingSeconds !== 0}
            >
              {!loading ? (
                'Submit'
              ) : (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
