import React from 'react'
import Lottie from 'lottie-react'
import animationData from '../../_metronic/assets/lotties/news-loading-animation.json'

export default function NewsLoadingAnimation() {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '70vh'
    }}>
      <Lottie animationData={animationData} />
    </div>
    
  )
}
